import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { deriveRawToItem, initialItem } from '../../models/item';
import {
  ItemActionTypes,
  FETCH_ITEM_SUCCESS,
  CLEAN_ITEM,
  UPDATE_ITEM_SUCCESS,
  ITEM_FAILURE,
  DELETE_ITEM_SUCCESS,
  UPDATE_MODIFIER_GROUP,
  DELETE_MODIFIER_GROUP,
  FETCH_ITEMS_SUCCESS,
  FETCH_MG_SUCCESS,
  REORDER_ITEM,
  TOGGLE_PROP,
  TOGGLE_SETTING_PROP,
  FETCH_MOCK_ITEMS_SUCCESS,
  CLEAN_MOCK,
  FETCH_LIST_SUCCESS,
  ADD_TO_ITEM_LIST,
} from './types';
import loader from '$gcomponents/reusables/loader';
import { deriveRawToModifierGroup } from '../../models/modifierGroup';
import intl from '$gintl';
import { generateGetParam } from '$gbusiness/helpers/util';
import { LOADED } from '$gbusiness/redux/loading/types';

export function fetchItemsSimple(param: any = null): any {
  return async (dispatch: Dispatch) => {
    const data = { ...param, pageSize: 9999 };
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.item.general + `?${generateGetParam(data)}`,
      method: 'GET',
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, ITEM_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: LOADED,
    });

    return Promise.resolve(response.list);
  };
}

export function fetchItems(param: any = null, ignoreMg = false): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.item.general + `?${generateGetParam(param)}`,
      method: 'GET',
      param,
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, ITEM_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    if (!ignoreMg) {
      const response2 = await fetchApi({
        url: configs.api.modifierGroup,
        method: 'GET',
      });
      if (!response || !response?.list) {
        return;
      }

      dispatch({
        type: FETCH_MG_SUCCESS,
        modifierGroups: response2.list.map((m) => deriveRawToModifierGroup(m)),
      });
    }

    dispatch({
      type: FETCH_ITEMS_SUCCESS,
      items: response.list.map((i) => deriveRawToItem(i)),
    });
  };
}

export function fetchItemList(): any {
  return async (dispatch: Dispatch, getState) => {
    const length = getState()?.item?.list?.length;
    if (length > 0) return;
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.item.list,
      method: 'GET',
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, ITEM_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }
    // const items = Object.entries(response.list).map(([id, name]) => ({ id: parseInt(id), name }));
    const list = response.list;
    dispatch({
      type: FETCH_LIST_SUCCESS,
      list,
    });
  };
}

export function fetchMockItems(): any {
  const param = {
    pageSize: 1000,
  };
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.item.autocomplete,
      method: 'POST',
      param,
    });

    if (!response || !response?.items) {
      handleApiFail(dispatch, ITEM_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_MOCK_ITEMS_SUCCESS,
      items: response.items.map((i) => deriveRawToItem(i)),
    });
  };
}

export function fetchItem(itemId): any {
  return async (dispatch: Dispatch) => {
    if (!itemId || isNaN(itemId)) {
      dispatch({
        type: FETCH_ITEM_SUCCESS,
        item: initialItem,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.item.general + '/' + itemId + `?${generateGetParam(null)}`,
      method: 'GET',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, ITEM_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_ITEM_SUCCESS,
      item: deriveRawToItem(response.data),
    });
  };
}

export function importPhotos(files): any {
  return async (dispatch: Dispatch, getState) => {
    let current = 1;
    const max = files.length;
    const thisLoader = loader({ message: 'PROGRESS.FILE_UPLOAD', key: { current, max } });

    const url = `${process.env.REACT_APP_API_BASE}/${configs.api.file.general}`;
    const store = getState();
    const globalHeaders = store.init.headers;
    const { accessToken, userId } = store.localStorage;

    for (const file of files) {
      if (thisLoader) {
        thisLoader.message = intl('PROGRESS.FILE_UPLOAD', { current, max });
      }

      const sku = file.name
        .replace(/\.[^/.]+$/, '')
        .split('_')
        .shift();
      const formData = new FormData();
      formData.append('file', file);
      formData.append('module', 'item');
      formData.append('moduleQuery', sku);

      await fetch(url, {
        method: 'POST',
        body: formData,
        referrer: '',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ...(userId && { userId: userId?.toString() }),
          ...globalHeaders,
        },
      });

      current++;
    }
    if (thisLoader) thisLoader.dismiss();
  };
}

export function importInventory(items, warehouses): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.item.importInventory,
      param: {
        items,
        warehouses,
      },
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, ITEM_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_ITEM_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function importItems(items): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.item.import,
      param: {
        items,
      },
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, ITEM_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_ITEM_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function saveItem(itemId, item, isRanking): any {
  return async (dispatch: Dispatch) => {
    if (!isRanking) dispatchLoading(dispatch, 'PROGRESS.SAVING');
    else {
      dispatch({
        type: REORDER_ITEM,
        itemId,
        rank: item.rank,
      });
    }

    const response = await fetchApi({
      url: configs.api.item.general + (itemId ? '/' + itemId : ''),
      param: {
        ...(itemId && { ...itemId }),
        ...item,
      },
      method: itemId ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, ITEM_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      if (!isRanking) handleApiSuccess(dispatch, UPDATE_ITEM_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
      if (!itemId) {
        const data = response.data;
        dispatch({
          type: ADD_TO_ITEM_LIST,
          item: {
            id: data.id,
            name: data.name,
            barcode: data.barcode,
            sku: data.sku,
          },
        });
      }
      return Promise.resolve(true);
    }
  };
}

export function deleteItem(itemId): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.item.general + (itemId ? '/' + itemId : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, ITEM_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_ITEM_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
    }
  };
}

export function deleteItems(ids): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.item.deleteMany,
      method: 'POST',
      param: {
        ids,
      },
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, ITEM_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_ITEM_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
    }
  };
}

export function transferQuantity(param): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.item.transferQuantity,
      method: 'POST',
      param,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, ITEM_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, null, 'MESSAGE.SAVE_SUCCESS');
      dispatch({
        type: FETCH_ITEM_SUCCESS,
        item: deriveRawToItem(response.data),
      });
    }
  };
}

export function addQuantity(param): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.item.addQuantity,
      method: 'POST',
      param,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, ITEM_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, null, 'MESSAGE.SAVE_SUCCESS');
      dispatch({
        type: FETCH_ITEM_SUCCESS,
        item: deriveRawToItem(response.data),
      });
      // return Promise.resolve(deriveRawToItem(response.data));
    }
  };
}

export function updateModifierGroup(modifierGroup, index = -1) {
  return {
    type: UPDATE_MODIFIER_GROUP,
    index,
    modifierGroup,
  };
}

export function deleteModifierGroup(index) {
  return {
    type: DELETE_MODIFIER_GROUP,
    index,
  };
}

export function toggleProp(id, param): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.item.general + '/' + id,
      param: {
        id,
        ...param,
      },
      method: 'PUT',
    });

    if (response && response?.success) {
      dispatch({
        type: TOGGLE_PROP,
        id,
        value: param,
      });
    }
  };
}

export function toggleSettingProp(id, param): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.item.general + '/' + id,
      param: {
        id,
        settings: {
          ...param,
        },
      },
      method: 'PUT',
    });

    if (response && response?.success) {
      dispatch({
        type: TOGGLE_SETTING_PROP,
        id,
        value: param,
      });
    }
  };
}

export function cleanMock(): ItemActionTypes {
  return { type: CLEAN_MOCK };
}

export function dehydrate(): ItemActionTypes {
  return { type: CLEAN_ITEM };
}
