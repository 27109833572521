import React, { useState } from 'react';
import intl from '$intl';
import ItemModel from '$fbusiness/models/item';
import WarehouseModel from '$fbusiness/models/warehouse';
import { DistributionsWrapper } from './styles';
import { HeaderTitle } from '$gcomponents/primitives';
import { Col, Row } from '$gstyles';
import { TextField } from '@mui/material';
import { Edit, SwapHoriz } from '@mui/icons-material';
import DistributionModal from './distributionModal';
import { useDispatch } from 'react-redux';
import { itemActions } from '$fbusiness/redux/item';
import { deriveWarehouseDistributions } from './utils';
import TransferModal from './transferModal';
import { Flex } from '$gstyles/wrapper';

interface DistributionsProps {
  item: ItemModel;
  className?: string;
  onUpdateQuantity: Function;
  onRefreshHistory?: Function;
  warehouses: Array<WarehouseModel>;
  hasInput?: boolean;
}

const Distributions: React.FC<DistributionsProps> = ({
  warehouses: initialWarehouses,
  className,
  item,
  onUpdateQuantity,
  onRefreshHistory,
  hasInput = false,
}) => {
  const dispatch = useDispatch();
  const [editItem, setEditItem] = useState<any>(null);
  const [transferItem, setTransferItem] = useState<any>(null);

  const onUpdate = (index, value) => {
    const newWarehouses = warehouses.map((warehouse, i) => {
      if (i === index) {
        return { ...warehouse, quantity: Number(value) };
      }
      return warehouse;
    });
    onUpdateQuantity(newWarehouses);
  };

  const onEdit = (index) => {
    setEditItem(warehouses[index]);
  };

  const onTransfer = (index) => {
    setTransferItem(warehouses[index]);
  };

  const onAdd = async (param) => {
    await dispatch(itemActions.addQuantity(param));
    if (onRefreshHistory) onRefreshHistory();
    setEditItem(null);
  };

  const onTransferConfirm = async (param) => {
    await dispatch(itemActions.transferQuantity(param));
    if (onRefreshHistory) onRefreshHistory();
    setTransferItem(null);
  };

  const warehouses = deriveWarehouseDistributions(initialWarehouses, item);

  return (
    <DistributionsWrapper className={className}>
      <HeaderTitle>
        <div>{intl('SCREEN.WAREHOUSES.TITLE_DISTRIBUTION')}</div>
      </HeaderTitle>
      {warehouses.map((warehouse, i) => (
        <Row key={i}>
          <Col gridSize={6} className="warehouse-row">
            <div className="name">{warehouse.warehouse}</div>
            {hasInput ? (
              <span>
                <TextField
                  className="qty-input"
                  variant="outlined"
                  size="small"
                  value={warehouse.quantity}
                  onChange={(e) => onUpdate(i, e.target.value)}
                />
              </span>
            ) : (
              <span>{warehouse.quantity}</span>
            )}
            <Flex className="actions">
              <span title="Transfer Inventory">
                <SwapHoriz className="transfer-icon pointer" onClick={() => onTransfer(i)} />
              </span>
              <span title="Edit Quantity">
                <Edit className="edit-icon pointer" onClick={() => onEdit(i)} />
              </span>
            </Flex>
          </Col>
        </Row>
      ))}
      <DistributionModal distribution={editItem} onClose={() => setEditItem(null)} onSubmit={onAdd} />
      <TransferModal
        distribution={transferItem}
        warehouses={warehouses}
        onClose={() => setTransferItem(null)}
        onSubmit={onTransferConfirm}
      />
    </DistributionsWrapper>
  );
};

export default Distributions;
