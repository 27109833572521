import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const BATCH_QTY_FORM = (): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'SCREEN.BATCH.COLS.QUANTITY',
        value: 'quantity',
        input: INPUT.NUMBER,
        rows: 10,
        gridSize: 5,
        validation: [input.requiredValidation],
      },
    ],
  },
];

export const ADD_SERIAL_FORM = (warehouses): Array<InputRowModel> => [
  {
    items: [
      ...(warehouses.length > 0
        ? [
            {
              label: 'SCREEN.BATCH.COLS.WAREHOUSE',
              value: 'warehouseId',
              input: INPUT.SELECT,
              options: warehouses.map((v) => ({
                labelText: v.name,
                value: v.id,
              })),
              gridSize: 6,
              validation: [input.requiredValidation],
            },
          ]
        : []),
      {
        label: 'SCREEN.BATCH.COLS.QUANTITY',
        value: 'quantity',
        input: INPUT.NUMBER,
        rows: 10,
        gridSize: 5,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.SERIAL.COLS.SERIALS',
        value: 'serials',
        input: INPUT.TEXTAREA,
        rows: 10,
        gridSize: 10,
        validation: [input.requiredValidation],
      },
    ],
  },
];

export default ADD_SERIAL_FORM;
