import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const WAREHOUSE_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'SCREEN.WAREHOUSES.COLS.NAME',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.WAREHOUSES.COLS.SHORT',
        value: 'short',
        input: INPUT.TEXT,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.WAREHOUSES.COLS.ADDRESS',
        value: 'address',
        input: INPUT.ADDRESS,
        gridSize: 12,
        postfix: 'address',
      },
    ],
  },
];
