import DistributionItemModel, { deriveRawToDistributionItem } from './distributionItem';
import OrderInvoiceItemModel, { deriveRawToOrderInvoiceItem } from './orderInvoiceItem';
import VendorModel, { deriveRawToVendor } from './vendor';

export default interface OrderDetailsModel extends OrderInvoiceItemModel {
  orderId: number;
  qtyOrdered?: number;
  qtySent?: number;
  qtyReceived?: number;
  totalSent?: number;
  totalReceived?: number;
  vendor?: VendorModel;
  categoryStr: string;
  deptStr: string;
  depts?: Array<string>;
  warehouseId?: number;
  distributions?: Array<DistributionItemModel>;
}

export const deriveRawToOrderDetails = (raw, isInvoice = false) => {
  return {
    ...deriveRawToOrderInvoiceItem(raw, isInvoice),
    orderId: raw.orderId,
    qtyOrdered: raw.qtyOrdered || raw.pricing.qty || 0,
    qtySent: raw.qtySent || 0,
    qtyReceived: raw.qtyReceived || 0,
    totalSent: Number(raw.totalSent) || 0,
    totalReceived: Number(raw.totalReceived) || 0,
    categoryStr: (raw.categories || []).map((d) => d.name).join(', '),
    deptStr: (raw.departments || []).map((d) => d.name).join(', '),
    ...(raw.warehouseId && { warehouseId: raw.warehouseId }),
    distributions: raw.distributions.map(deriveRawToDistributionItem),
    ...(raw.vendor && { vendor: deriveRawToVendor(raw.vendor) }),
  };
};
