import { configs } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import { Button } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { format } from '$gbusiness/helpers/date';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { deriveRawToItemBatch } from '$fbusiness/models/itemBatch';
import PATH from '$business/enums/paths';
import { Link } from '$gcomponents/primitives';
import ExportModel from '$gbusiness/models/export';

export const defaultBatchColumns = [
  'item.name',
  'item.barcode',
  'item.sku',
  'vendor.name',
  'quantity',
  'receiveDate',
  'expirationDate',
  'warehouse.name',
  'lotNo',
  'binNo',
  'memo',
];

export const batchExportSettings = (columns: any = []): ExportModel => {
  const cols: any = columns.reduce((acc, c) => ((acc[c] = true), acc), {}); // eslint-disable-line no-sequences
  return {
    url: configs.api.batch.general,
    method: 'GET',
    deriveToModel: deriveRawToItemBatch,
    deriveTable: (item) => ({
      ReceiveNo: item.receiveNo,
      ...(cols['item.name'] && { ItemName: item.name }),
      ...(cols['barcode'] && { barcode: item.barcode }),
      ...(cols['sku'] && { sku: item.sku }),
      ...(cols['vendor'] && { vendor: item.vendor?.name || '' }),
      ...(cols['quantity'] && { Qty: item.quantity || 0 }),
      ...(cols['receiveDate'] && { ReceiveDate: item.receiveDate || '' }),
      ...(cols['expirationDate'] && { ExpirationDate: item.expirationDate || '' }),
      ...(cols['warehouse.name'] && { Warehouse: item.warehouse.name || '' }),
      ...(cols['lotNo'] && { LotNo: item.lotNo || '' }),
      ...(cols['binNo'] && { BinNo: item.binNo || '' }),
      ...(cols['memo'] && { Memo: item.memo || '' }),
    }),
    sortKey: 'receiveDate',
    sortOrder: SORT.DSC,
  };
};

export const BATCH_TABLE_CONFIG = (isItemPage, isExpiration = false): TableModel => ({
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.batch.general,
    deriveToModel: deriveRawToItemBatch,
    method: 'GET',
    defaultSortKey: isItemPage ? 'updatedAt' : isExpiration ? 'expirationDate' : 'receiveDate',
    defaultSortOrder: isItemPage || !isExpiration ? SORT.DSC : SORT.ASC,
    shouldVirtualize: false,
    pageSize: isItemPage ? 10 : 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    isStripped: !isExpiration,
    minWidth: isItemPage ? 300 : 600,
    maxWidth: 1400,
    rowHeight: 30,
    disableSort: isItemPage,
    cellPadding: `${isItemPage ? 2 : 8}px 4px`,
    borderColor: 'var(--ion-border-color)',
    responsive: true,
    ...(isExpiration && {
      setRowClass: (row) => {
        if (row.remainingDays < 0) return 'danger';
        if (row.remainingDays < row.expireThreshold) return 'warning';
        if (row.remainingDays < row.expireThreshold2) return 'light';
        return '';
      },
    }),
  },
  filter: {
    color: COLORS.PRIMARY,
  },
});

export const BATCH_TABLE = ({ isItemPage, onClickReceive, settings, isExpiration = false }): Array<any> => [
  {
    label: 'SCREEN.BATCH.COLS.RECEIVENO',
    value: 'receiveNo',
    align: 'center',
    component: (row) => (
      <span className="link" onClick={() => onClickReceive(row)}>
        {row.receiveNo}
      </span>
    ),
    alwaysShow: true,
    width: 80,
  },
  ...(isItemPage
    ? []
    : [
        {
          label: 'SCREEN.BATCH.COLS.ITEM',
          value: 'item.name',
          component: (row) => <Link route={`${PATH.ITEMS}/${row.item?.id}`}>{row.item?.name || ''}</Link>,
          alwaysShow: true,
          width: 250,
        },
        {
          label: 'SCREEN.BATCH.COLS.BARCODE',
          value: 'item.barcode',
          component: (row) => row.item?.barcode || '',
          width: 180,
        },
        {
          label: 'SCREEN.BATCH.COLS.SKU',
          value: 'item.sku',
          component: (row) => row.item?.sku || '',
          width: 150,
        },
        {
          label: 'SCREEN.BATCH.COLS.VENDOR',
          value: 'vendor.name',
          component: (row) => <>{row?.vendor?.name || ''}</>,
          sortable: SORT.ASC,
          width: 200,
        },
      ]),

  {
    label: 'SCREEN.BATCH.COLS.QUANTITY',
    value: 'quantity',
    className: 'main',
    align: 'center',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 100,
  },
  {
    label: 'SCREEN.BATCH.COLS.RECEIVE_DATE',
    value: 'receiveDate',
    component: (row) => <>{format(row.receiveDate, DATE_FORMATS.SLASH)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.BATCH.COLS.SELL_BY',
    value: 'sellByDate',
    component: (row) => <>{format(row.sellByDate, DATE_FORMATS.SLASH)}</>,
    sortable: SORT.ASC,
    width: 200,
  },
  ...(!isExpiration
    ? [
        {
          label: 'SCREEN.BATCH.COLS.EXPIRY',
          value: 'expirationDate',
          component: (row) => <>{format(row.expirationDate, DATE_FORMATS.SLASH)}</>,
          sortable: SORT.ASC,
          width: 200,
        },
      ]
    : []),
  {
    label: 'SCREEN.BATCH.COLS.WAREHOUSE',
    value: 'warehouse.name',
    isHidden: !settings?.inventory,
    component: (row) => <>{row.warehouse?.name || ''}</>,
    sortable: SORT.ASC,
    align: 'center',
    width: 150,
  },
  {
    label: 'SCREEN.BATCH.COLS.LOTNO',
    value: 'lotNo',
    component: (row) => <>{row.lotNo}</>,
    sortable: SORT.ASC,
    width: 100,
  },
  {
    label: 'SCREEN.BATCH.COLS.BINNO',
    value: 'binNo',
    component: (row) => <>{row.binNo}</>,
    sortable: SORT.ASC,
    width: 100,
  },
  ...(isExpiration
    ? [
        {
          label: '',
          value: 'memo',
          className: 'expiration',
          component: (row) => {
            if (row.remainingDays < 0) return `Expired (${row.expirationDate})`;
            if (row.remainingDays < row.expireThreshold)
              return `Will Expire in ${row.remainingDays} days (${row.expirationDate})`;
            if (row.remainingDays === 9999) return 'No Expiration';
            return `Will Expire on ${row.expirationDate}`;
          },
          alwaysShow: true,
          sortable: SORT.ASC,
          width: 300,
        },
      ]
    : [
        {
          label: 'SCREEN.BATCH.COLS.MEMO',
          value: 'memo',
          component: (row) => <>{row.memo}</>,
          sortable: SORT.ASC,
          width: 250,
        },
      ]),
  ...(isItemPage
    ? []
    : [
        {
          label: '',
          value: '',
          className: 'action vertical',
          align: 'center',
          alwaysShow: true,
          component: (row, actions) => (
            <Button
              className="left no-space"
              color="secondary"
              fullWidth
              onClick={(e) => actions.handlePopoverOpen(e, row)}>
              <MoreHorizIcon />
            </Button>
          ),
          width: 80,
        },
      ]),
];
