import { STORE_FILTER_OPTIONS } from '$fbusiness/enums/options/storeFilter';
import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import states from '$gbusiness/enums/options/states';
import { insertEmptyOption } from '$gbusiness/helpers/input';
import InputRowModel from '$gbusiness/models/inputRow';

const STORE_FILTER_FORM = ({
  settings,
  stores,
  regions,
  users,
  onChangeUser,
  onChangeStore,
}): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.AUTOCOMPLETE,
        label: 'SCREEN.STORES.COLS.STORE',
        size: 'small',
        disableFastField: true,
        gridSize: 4,
        value: 'store',
        config: {
          optionProp: {
            label: 'name',
            labelText: (o) => `${o.name || ''} ${o.code ? `(${o.code})` : ''}`,
            value: 'id',
          },
          data: stores,
          displayInactive: ' - Inactive',
        },
        onChange: ({ formValues, newValue }) => onChangeStore(formValues, newValue),
      },
      ...(users.length
        ? [
            {
              input: INPUT.AUTOCOMPLETE,
              label: 'SCREEN.STORES.COLS.SALESMAN',
              size: 'small',
              disableFastField: true,
              gridSize: 4,
              value: 'user',
              config: {
                optionProp: {
                  label: 'name',
                  value: 'userId',
                },
                data: users,
              },
              onChange: ({ formValues, newValue }) => onChangeUser(formValues, newValue),
            },
          ]
        : [
            {
              ...INPUT_TYPES.HIDDEN,
              gridSize: 4,
            },
          ]),
    ],
  },
  {
    items: [
      {
        isHidden: () => !settings?.regions,
        input: INPUT.SELECT,
        label: 'SCREEN.STORES.COLS.REGION',
        size: 'small',
        disableFastField: true,
        gridSize: 4,
        value: 'regionId',
        options: insertEmptyOption(
          regions.map((m) => ({
            labelText: m.name,
            value: m.id,
          })),
          'All',
        ),
      },
      {
        ...INPUT_TYPES.STATE,
        value: 'state',
        size: 'small',
        gridSize: 3,
        options: insertEmptyOption(states, 'All', '0'),
      },
      {
        input: INPUT.SELECT,
        label: 'SCREEN.STORES.SHOW_INACTIVE',
        value: 'active',
        size: 'small',
        options: STORE_FILTER_OPTIONS,
        gridSize: 4,
      },
      // {
      //   ...INPUT_TYPES.CITY,
      //   value: 'city',
      //   size: 'small',
      //   gridSize: 3,
      // },
    ],
  },
];

export default STORE_FILTER_FORM;
