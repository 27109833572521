import React from 'react';
import { Formik } from 'formik';

import { input as inputHelper } from '$gbusiness/helpers';
import intl from '$intl';
import { FilterWrapper } from './styles';
import STORE_FILTER_FORM from './filters';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import StoreModel from '$fbusiness/models/store';
import UserModel from '$gbusiness/models/user';
import RegionModel from '$fbusiness/models/region';
import ExportButton from '$gcomponents/reusables/exportButton';
import { storeExportSettings } from './table';
import { Print } from '@mui/icons-material';

interface StoreFilterProps {
  settings: any;
  regions: Array<RegionModel>;
  onSearch: Function;
  stores: Array<StoreModel>;
  users: Array<UserModel>;
  columns: any;
  parentFilter?: any;
}

export const defaultStoreFilter = {
  forceRefresh: true,
  city: '',
  state: '0',
  regionId: '0',
  active: 'ALL',
};

const StoreFilter: React.FC<StoreFilterProps> = ({ regions, columns, settings, stores, users, onSearch }) => {
  const initialValues = {
    ...defaultStoreFilter,
  };

  const toFilter = (values) => {
    return {
      ...values,
      userId: values?.user?.userId || null,
      storeId: values?.store?.id || null,
    };
  };
  const onSubmit = (values) => {
    onSearch(toFilter(values));
  };
  const onChangeUser = (values, value) => {
    onSearch({ ...values, userId: value?.userId || null });
  };
  const onChangeStore = (values, value) => {
    onSearch({ ...values, storeId: value?.id || null });
  };

  const filterForm = STORE_FILTER_FORM({ settings, regions, stores, users, onChangeUser, onChangeStore });

  const validateForm = (values) => {
    return inputHelper.validateError(filterForm, values);
  };

  const onPrint = () => {
    window.print();
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <FilterWrapper>
          <FormSection
            FORM={filterForm}
            formik={formik}
            lastColumn={
              <Button
                onClick={formik.handleSubmit}
                className="submit-button"
                variant="contained"
                size="large"
                color="secondary">
                {intl('BUTTON.SUBMIT')}
              </Button>
            }
          />
          <div className="export-container">
            <Button variant="text" color="secondary" icon={<Print />} onClick={onPrint}>
              {intl('BUTTON.PRINT')}
            </Button>
            {columns && (
              <ExportButton
                filter={toFilter(formik.values)}
                variant="text"
                fileName={intl('COMMON.STORE')}
                exportSetting={storeExportSettings(settings, columns)}
              />
            )}
          </div>
        </FilterWrapper>
      )}
    </Formik>
  );
};

export default StoreFilter;
