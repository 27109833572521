import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { Button, Text } from '$gcomponents/primitives';
import { AppForm } from '$styles/general';
import { factoryActions } from '$fbusiness/redux/factory';
import { AppModel } from '$business/redux/';
import VendorModel from '$fbusiness/models/vendor';

import { VENDOR_FORM } from './vendorForm';
import { Flex } from '$gstyles/wrapper';
import StoreModel from '$fbusiness/models/store';
import StoreSelector from '../../filesScreen/fileModal/storeSelector';
import { IonContent } from '@ionic/react';
import Footer from '$gcomponents/widgets/footer';

interface VendorDetailsProps {
  vendor: VendorModel;
  stores: Array<StoreModel>;
  onClose: Function;
}

const VendorDetails: React.FC<VendorDetailsProps> = ({ vendor, stores, onClose }) => {
  const dispatch = useDispatch();
  const isFinished = useSelector((state: AppModel) => state.factory.isFinished);
  const formRef = useRef<any>();
  const [selectStores, setSelectStores] = useState<number[] | null>(vendor.storeIds || null);

  useEffect(() => {
    if (!isFinished) return;

    dispatch(factoryActions.resetFinished());
    onClose();
  }, [isFinished, dispatch, onClose]);

  if (!vendor) return null;
  const { id, ...initialValues } = vendor;

  const onSubmit = (values) => {
    const {
      settings: { address, formatted, ...newSettings },
    } = values;
    const newValues = {
      ...values,
      storeIds: selectStores,
      settings: {
        ...newSettings,
        address: formatted,
      },
    };
    dispatch(factoryActions.saveVendor(id || 0, newValues));
  };

  const validateForm = (values) => {
    return input.validateError(VENDOR_FORM, values);
  };

  const onStoreSelect = (values) => {
    setSelectStores(values);
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <div className="content">
              <Flex className="stores-wrapper">
                <StoreSelector
                  className="oneline"
                  stores={stores}
                  onChange={onStoreSelect}
                  values={selectStores}
                />
              </Flex>
              <AppForm>
                <FormSection FORM={VENDOR_FORM} formik={formik} />
              </AppForm>
            </div>
          </IonContent>
          <Footer justifyContent="center">
            <Button className="half" onClick={formik.handleSubmit} disabled={!formik.isValid}>
              <Text k="BUTTON.SAVE" />
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default VendorDetails;
