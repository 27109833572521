import AddressModel from '$gbusiness/models/address';
import { toLocal } from '$gbusiness/helpers/date';
import { parseAddress } from '$gbusiness/helpers/util';
import TaxModel, { deriveRawToTax } from './tax';
import VendorModel, { deriveRawToVendor } from './vendor';
import ShippingModel, { deriveRawToShipping } from './shipping';
import DiscountModel, { deriveRawToDiscount } from './discount';
import CommRuleModel, { deriveRawToCommRule } from './commRule';
import RegionModel, { deriveRawToRegion } from './region';
import BankModel, { deriveRawToBank } from './bank';
import TermModel, { deriveRawToTerm } from './term';
import { SimpleModel } from '$gbusiness/models/simple';
import { INVOICE_NUMBER } from '../enums/options/invoiceNumber';
import ChargeModel from './charge';
import UserModel from './user';
import { deriveRawToUser } from '$gbusiness/models/user';
import WarehouseModel, { deriveRawToWarehouse } from './warehouse';
import InvoiceTypeModel, { deriveRawToInvoiceType } from './invoiceType';
import StoreLevelModel, { deriveRawToStoreLevel } from './storeLevel';

export default interface FactoryModel {
  factoryId: number;
  id?: number;
  name: string;
  code: string;
  type: string;
  formatted?: string;
  address?: AddressModel | undefined;
  placeId?: string;
  email: string;
  phone: string;
  createdAt?: string;
  logo?: string;
  vendors: Array<VendorModel>;
  taxes: Array<TaxModel>;
  discounts: Array<DiscountModel>;
  commRules: Array<CommRuleModel>;
  shippings: Array<ShippingModel>;
  shippingMethods: Array<SimpleModel>;
  charges?: Array<ChargeModel>;
  regions: Array<RegionModel>;
  banks: Array<BankModel>;
  terms: Array<TermModel>;
  users?: Array<UserModel>;
  invoiceTypes?: Array<InvoiceTypeModel>;
  storeLevels?: Array<StoreLevelModel>;
  warehouses?: Array<WarehouseModel>;
  enums: any;
  settings?: any;
  updatedAt?: string;
}

export const deriveEnums = (enums) => {
  return enums.reduce((acc: any, s) => {
    return {
      ...acc,
      [s.type]: s.enums,
    };
  }, {});
};

export const deriveRawToFactory = (raw) => {
  return {
    factoryId: raw.id,
    id: raw.id,
    name: raw.name,
    code: raw.code,
    type: raw.type,
    formatted: raw.address,
    ...(raw.address && { address: parseAddress(raw.address) }),
    placeId: raw.placeId,
    email: raw.email,
    phone: raw.phone,
    ...(raw.image && { logo: raw.image?.fileUrl }),
    createdAt: toLocal(raw.createdAt),
    updatedAt: toLocal(raw.updatedAt),
    vendors: (raw.vendors || []).map(deriveRawToVendor),
    warehouses: (raw.warehouses || []).map(deriveRawToWarehouse),
    invoiceTypes: (raw.invoiceTypes || []).map(deriveRawToInvoiceType),
    storeLevels: (raw.storeLevels || []).map(deriveRawToStoreLevel),
    commRules: (raw.commRules || []).map(deriveRawToCommRule),
    discounts: (raw.discounts || []).map(deriveRawToDiscount),
    regions: (raw.regions || []).map(deriveRawToRegion),
    taxes: (raw.taxes || []).map(deriveRawToTax),
    shippings: (raw.shippingOptions || []).map(deriveRawToShipping),
    shippingMethods: raw.shippingMethods || [],
    enums: deriveEnums(raw.enums) || {},
    charges: raw.charges || [],
    banks: (raw.banks || []).map(deriveRawToBank),
    terms: (raw.terms || []).map(deriveRawToTerm),
    users: (raw.users || []).map(deriveRawToUser),
    settings: {
      ...factoryFeatures,
      ...(raw.settings || {}),
    },
    // shippings: raw.shippings ? raw.shippings.map(v => deriveRawToShipping(v)) : mockShippings, // remove mocks later
  };
};

export const factoryFeatures: any = {
  invoiceStyle: INVOICE_NUMBER.DEFAULT,
  invoiceSuffix: '',
  invoiceStarting: 100000,
  invoice: false,
  invoiceEmail: false,
  createInvoice: false,
  editInvoice: false,
  subcategory: false,
  file: false,
  productSize: 200,
  card: false,
  itemCombine: true,
  ebrochure: false,
  notification: false,
  textConfirmation: false,
  hidePrice: false,
  pickupOnly: false,
  kongdog: false,
  wholesaleEmail: '',
  salesmen: false,
  showVendor: false,
  showAccess: false,
  retailEmail: '',
  commission: false,
  discounts: false,
  refundVoucher: true,
  regions: false,
  terms: false,
  dailyReport: false,
  hidePoStatus: false,
  storeToCustomer: false,
  disableCustomer: false,
  emailStoreCreation: false,
  fullRefundOnly: true,
  deleteInvoice: true,
  storePane: false,
  showInvoices: true,
  showTransactions: false,
  creditPaymentDropdown: false,
  customCredit: true,
  serial: false,
  batch: false,
  inventory: false,
  expiration: false,
};

export const initialFactory: FactoryModel = {
  factoryId: 0,
  name: '',
  code: '',
  type: '',
  formatted: '',
  placeId: '',
  email: '',
  phone: '',
  vendors: [],
  warehouses: [],
  taxes: [],
  shippings: [],
  shippingMethods: [],
  charges: [],
  commRules: [],
  discounts: [],
  regions: [],
  banks: [],
  terms: [],
  enums: [],
  users: [],
  settings: {
    ...factoryFeatures,
  },
};

export const getFactoryUsers = (factory) => {
  return factory.users || [];
};
