import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { deriveRawToStore, initialStore } from '../../models/store';
import {
  StoreActionTypes,
  CLEAN_STORE,
  UPDATE_STORE_SUCCESS,
  STORE_FAILURE,
  DELETE_STORE_SUCCESS,
  RESET_FINISHED,
  FETCH_STORE_SUCCESS,
  FETCH_STORES_SUCCESS,
  UPDATE_CREDIT_SUCCESS,
} from './types';
import { setStorageItem } from '$gbusiness/services/local.storage';
import { LOADED } from '$gbusiness/redux/loading/types';

export function fetchStore(id, localStorage = false): any {
  return async (dispatch: Dispatch) => {
    if (!id || isNaN(id)) {
      dispatch({
        type: FETCH_STORE_SUCCESS,
        store: initialStore,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.store.general + '/' + id,
      method: 'GET',
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, STORE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    const store = deriveRawToStore(response.data);

    if (localStorage) {
      await setStorageItem('storeId', store.id);
    }

    dispatch({
      type: FETCH_STORE_SUCCESS,
      store,
    });
  };
}

export function fetchStores(): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.store.general,
      method: 'GET',
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, STORE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_STORES_SUCCESS,
      stores: response.list.map(deriveRawToStore),
    });
  };
}

export function saveStore(id, store): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.store.general + (id ? '/' + id : ''),
      param: {
        ...(id && { ...id }),
        ...store,
      },
      method: id ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, STORE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_STORE_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
      dispatch({
        type: FETCH_STORE_SUCCESS,
        store: deriveRawToStore(response.data),
      });
    }
  };
}

export function deleteStore(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.store.general + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, STORE_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_STORE_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
    }
  };
}

export function createCredit(param): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: param.id ? configs.api.refund + '/' + param.id : configs.api.store.credit,
      param,
      method: param.id ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, STORE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      if (response.data) {
        dispatch({
          type: UPDATE_CREDIT_SUCCESS,
          credits: response.balance,
          storeId: response.data.storeId,
        });
        handleApiSuccess(dispatch, null, 'MESSAGE.SAVE_SUCCESS', 'small');
      }
    }
  };
}

export function fetchAvailableCredits(): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.store.credit + '?allStores=true&used=0&pageSize=200',
      method: 'GET',
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, STORE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    }
    dispatch({
      type: LOADED,
    });
    return Promise.resolve(response.list);
  };
}

export function resetFinished(): StoreActionTypes {
  return { type: RESET_FINISHED };
}

export function dehydrate(): StoreActionTypes {
  return { type: CLEAN_STORE };
}
