import FACTORY_TYPES, { FACTORY_TYPE } from '$fbusiness/enums/options/factoryType';
import INVOICE_TYPES, { INVOICE_NUMBER } from '$fbusiness/enums/options/invoiceNumber';
import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';
import { configs } from '$configs';

export const FACTORY_FORM = (
  settings,
  onChangeFactoryType: any = null,
  isAdmin = false,
): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'INPUT.LABEL.FACTORY_NAME',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 5,
        validation: [input.requiredValidation],
      },
      {
        label: 'INPUT.LABEL.FACTORY_CODE',
        value: 'code',
        input: INPUT.TEXT,
        gridSize: 3,
        validation: [input.requiredValidation],
      },
      ...(isAdmin
        ? [
            {
              label: 'INPUT.LABEL.FACTORY_TYPE',
              value: 'type',
              input: INPUT.SELECT,
              options: FACTORY_TYPES,
              gridSize: 4,
              validation: [input.requiredValidation],
              onChange: onChangeFactoryType,
            },
          ]
        : []),
      {
        ...INPUT_TYPES.PHONE,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.EMAIL,
      },
      {
        ...INPUT_TYPES.GOOGLE_ADDRESS,
        value: 'formatted',
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    isHidden: () => !settings?.createInvoice,
    title: 'Features',
    items: [
      {
        label: 'SCREEN.FACTORY.SETTINGS.INVOICE_AUTOFILL',
        value: 'settings.invoiceAutofill',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        ...INPUT_TYPES.HIDDEN,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.SETTINGS.INVOICE_DATA',
        value: 'settings.invoiceData',
        input: INPUT.TEXTAREA,
        rows: 5,
        gridSize: 5,
      },
      {
        label: 'SCREEN.FACTORY.SETTINGS.TERMS',
        value: 'settings.invoiceTerms',
        input: INPUT.TEXTAREA,
        rows: 5,
        gridSize: 7,
      },
    ],
  },
  {
    isHidden: () => !settings?.createInvoice,
    className: 'invoice-number',
    items: [
      {
        label: 'SCREEN.FACTORY.SETTINGS.INVOICE_NO_STYLE',
        value: 'settings.invoiceStyle',
        input: INPUT.RADIO,
        size: 'small',
        options: INVOICE_TYPES,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.SETTINGS.INVOICE_SUFFIX',
        value: 'settings.invoiceSuffix',
        input: INPUT.ALPHANUMERIC,
        maxLength: 10,
        isHidden: (value) => value.settings.invoiceStyle !== INVOICE_NUMBER.CUSTOM,
        disableFastField: true,
        gridSize: 3,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.FACTORY.SETTINGS.INVOICE_STARTING',
        value: 'settings.invoiceStarting',
        input: INPUT.NUMBERTEXT,
        isHidden: (value) => value.settings.invoiceStyle !== INVOICE_NUMBER.CUSTOM,
        disableFastField: true,
        gridSize: 3,
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    isHidden: () => !settings?.expiration,
    title: 'Product Expiration',
    items: [
      {
        label: 'SCREEN.FACTORY.SETTINGS.EXPIRE_DAYS',
        value: 'settings.expireDays',
        input: INPUT.NUMBER,
        defaultValue: 30,
        shrink: true,
        gridSize: 4,
        postfix: 'days',
      },
      {
        label: 'SCREEN.FACTORY.SETTINGS.EXPIRE_DAYS2',
        value: 'settings.expireDays2',
        input: INPUT.NUMBER,
        defaultValue: 60,
        shrink: true,
        gridSize: 4,
        postfix: 'days',
      },
    ],
  },
  {
    isHidden: () => !settings?.expiration,
    title: 'Invoice Options',
    items: [
      {
        label: 'SCREEN.FACTORY.SETTINGS.SHOW_ITEM_DESCRIPTION',
        value: 'settings.invoiceItemDescription',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
    ],
  },
];

export const FACTORY_FEATURES_FORM = (): Array<InputRowModel> => [
  {
    className: 'checks',
    items: [
      {
        label: 'SCREEN.FACTORY.FEATURES.INVOICE',
        value: 'settings.invoice',
        input: INPUT.CHECKBOX,
        gridSize: 12,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.CREATE_INVOICE',
        value: 'settings.createInvoice',
        input: INPUT.CHECKBOX,
        gridSize: 6,
        isHidden: (values) => !values.settings.invoice,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.EDIT_INVOICE',
        value: 'settings.editInvoice',
        input: INPUT.CHECKBOX,
        gridSize: 6,
        isHidden: (values) => !values.settings.invoice,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.STORE_PANE',
        value: 'settings.storePane',
        input: INPUT.CHECKBOX,
        gridSize: 6,
        isHidden: (values) => !values.settings.invoice,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.EMAIL_INVOICE',
        value: 'settings.invoiceEmail',
        input: INPUT.CHECKBOX,
        gridSize: 6,
        isHidden: (values) => !values.settings.invoice,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.SHOW_INVOICES',
        value: 'settings.showInvoices',
        input: INPUT.CHECKBOX,
        gridSize: 6,
        isHidden: (values) => !values.settings.invoice,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.SHOW_TRANSACTIONS',
        value: 'settings.showTransactions',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.SUBCATEGORY',
        value: 'settings.subcategory',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.FILES',
        value: 'settings.file',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.CREDIT_CARD',
        value: 'settings.card',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.NOTIFICATION',
        value: 'settings.notification',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.HIDE_PRICE',
        value: 'settings.hidePrice',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.PICKUP_ONLY',
        value: 'settings.pickupOnly',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.SHOW_VENDOR',
        value: 'settings.showVendor',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.DISCOUNTS',
        value: 'settings.discounts',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.SALESMEN',
        value: 'settings.salesmen',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.COMMISSION',
        value: 'settings.commission',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.DISABLE_STORE',
        value: 'settings.disableCustomer',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.REFUND_VOUCHER',
        value: 'settings.refundVoucher',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.REGIONS',
        value: 'settings.regions',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.DAILY_REPORT',
        value: 'settings.dailyReport',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.TERMS',
        value: 'settings.terms',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.HIDE_PO_STATUS',
        value: 'settings.hidePoStatus',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.STORE_TO_CUSTOMER',
        value: 'settings.storeToCustomer',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.EMAIL_STORE_CREATION',
        value: 'settings.emailStoreCreation',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.ITEM_COMBINE',
        value: 'settings.itemCombine',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.KONGDOG',
        value: 'settings.kongdog',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.FULL_REFUND_ONLY',
        value: 'settings.fullRefundOnly',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      // {
      //   label: 'SCREEN.FACTORY.FEATURES.PAID_REFUND_ONLY',
      //   value: 'settings.paidRefundOnly',
      //   input: INPUT.CHECKBOX,
      //   gridSize: 6,
      // },
      {
        label: 'SCREEN.FACTORY.FEATURES.BATCH',
        value: 'settings.batch',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.EXPIRATION',
        value: 'settings.expiration',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.SERIAL',
        value: 'settings.serial',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.CUSTOM_CREDIT',
        value: 'settings.customCredit',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.AUTO_REFUND_INVOICE',
        value: 'settings.autoRefundInvoice',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.INVENTORY',
        value: 'settings.inventory',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.ACCESS',
        value: 'settings.showAccess',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.STORE_LEVEL',
        value: 'settings.storeLevel',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
    ],
  },
  {
    items: [
      {
        label: 'SCREEN.FACTORY.FEATURES.CREDIT_PAYMENT_DROPDOWN',
        value: 'settings.creditPaymentDropdown',
        input: INPUT.CHECKBOX,
        gridSize: 12,
      },
    ],
  },
  {
    className: 'checks space-down',
    items: [
      {
        label: 'SCREEN.FACTORY.FEATURES.MESSAGING',
        value: 'settings.messaging',
        input: INPUT.CHECKBOX,
        gridSize: 12,
        onChange: ({ value, formik }) => {
          if (value < configs.productSizeThreshold) formik.setFieldValue('settings.productRanking', true);
          else formik.setFieldValue('settings.productRanking', false);
        },
      },
    ],
  },
  {
    isHidden: (values) => !values?.settings.messaging,
    lineSpace: -10,
    items: [
      {
        label: 'SCREEN.FACTORY.FEATURES.MESSAGE_FROM',
        value: 'settings.messageFrom',
        input: INPUT.TEXT,
        disableFastField: true,
        gridSize: 6,
      },
      {
        ...INPUT_TYPES.EMAIL,
        label: 'SCREEN.FACTORY.FEATURES.MESSAGE_EMAIL',
        value: 'settings.messageEmail',
        disableFastField: true,
        helperText: 'INPUT.PLACEHOLDER.AWS_EMAIL',
        gridSize: 6,
      },
    ],
  },

  {
    lineSpace: 30,
    items: [
      {
        ...INPUT_TYPES.EMAIL,
        label: 'SCREEN.FACTORY.FEATURES.RETAIL_EMAIL',
        value: 'settings.retailEmail',
      },
      {
        ...INPUT_TYPES.EMAIL,
        label: 'SCREEN.FACTORY.FEATURES.WHOLESALE_EMAIL',
        value: 'settings.wholesaleEmail',
      },
      {
        label: 'SCREEN.FACTORY.FEATURES.PRODUCT_SIZE',
        value: 'settings.productSize',
        input: INPUT.NUMBER,
        onChange: ({ value, formik }) => {
          if (value < configs.productSizeThreshold) formik.setFieldValue('settings.productRanking', true);
          else formik.setFieldValue('settings.productRanking', false);
        },
        gridSize: 6,
      },
      {
        isDisabled: (values) => values.settings?.productSize < configs.productSizeThreshold,
        disableFastField: true,
        label: 'SCREEN.FACTORY.FEATURES.PRODUCT_RANKING',
        value: 'settings.productRanking',
        input: INPUT.CHECKBOX,
        gridSize: 6,
      },
    ],
  },
  {
    isHidden: (values) => values?.type !== FACTORY_TYPE.ECOMMERCE,
    items: [
      {
        label: 'SCREEN.FACTORY.FEATURES.EBROCHURE',
        value: 'settings.ebrochure',
        input: INPUT.CHECKBOX,
        disableFastField: true,
        gridSize: 12,
      },
    ],
  },
  {
    items: [
      {
        label: 'SCREEN.FACTORY.FEATURES.TOC',
        value: 'settings.toc',
        input: INPUT.TEXTAREA,
        rows: 10,
        disableFastField: true,
        gridSize: 12,
      },
    ],
  },
];

export const FACTORY_LOGO_FORM = [
  {
    items: [
      {
        ...INPUT_TYPES.IMAGE,
        cacheField: 'updatedAt',
        gridSize: 8,
        value: 'logo',
        gridHeight: '100px',
        gridWidth: '300px',
      },
    ],
  },
];

export default FACTORY_FORM;
