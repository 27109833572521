import { initialStore } from '../../models/store';
import {
  CLEAN_STORE,
  CREATE_STORE_SUCCESS,
  DELETE_STORE_SUCCESS,
  StoreActionTypes,
  StoreReducerType,
  STORE_INIT_STATE,
  FETCH_STORE_SUCCESS,
  RESET_FINISHED,
  UPDATE_STORE_SUCCESS,
  FETCH_STORES_SUCCESS,
  UPDATE_CREDIT_SUCCESS,
  UPDATE_OPEN_BALANCE,
} from './types';

export default function storeReducer(
  state: StoreReducerType = STORE_INIT_STATE,
  action: StoreActionTypes,
): StoreReducerType {
  switch (action.type) {
    case FETCH_STORE_SUCCESS:
      return {
        ...state,
        store: action.store,
        stores: (state.stores || []).map((s) => (s.id === action.store.id ? { ...s, ...action.store } : s)),
      };
    case FETCH_STORES_SUCCESS:
      return {
        ...state,
        stores: action.stores,
      };
    case UPDATE_OPEN_BALANCE:
      const storeId = action.store?.id;
      if (!storeId) return state;
      return {
        ...state,
        stores: state.stores.map((s) =>
          s.id === storeId
            ? {
                ...s,
                openBalance: action.store.openBalance || 0,
                balanceOwed: action.store.balanceOwed,
              }
            : s,
        ),
      };
    case UPDATE_CREDIT_SUCCESS:
      return {
        ...state,
        store: {
          ...(state.store || initialStore),
          credits: action.credits,
        },
        stores: state.stores.map((s) => (s.id === action.storeId ? { ...s, credits: action.credits } : s)),
      };
    case UPDATE_STORE_SUCCESS:
    case CREATE_STORE_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case DELETE_STORE_SUCCESS:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case RESET_FINISHED:
      return {
        ...state,
        isFinished: false,
      };
    case CLEAN_STORE:
      return {
        ...STORE_INIT_STATE,
        stores: state.stores,
      };
    default:
      return state;
  }
}
