import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { usePapaParse } from 'react-papaparse';
import { ArrowBack } from '@mui/icons-material';

import intl from '$intl';
import { dialog, Table, TabSimple } from '$gcomponents/reusables';
import { IonContent } from '@ionic/react';
import Footer from '$gcomponents/widgets/footer';
import { Button } from '$gcomponents/primitives';
import { Link } from '$gstyles/general';

import { ModalWrapper } from './styles';
import PREVIEW_TABLE, { previewStyles, validateRow } from './previewTable';
import { itemActions } from '$fbusiness/redux/item';
import { initActions } from '$business/redux/init';
import OptionModel from '$gbusiness/models/option';
import { Flex } from '$gstyles/wrapper';
import FactoryModel from '$fbusiness/models/factory';
import QUANTITY_PREVIEW_TABLE, {
  validateRow as validateRowInventory,
  quantityPreviewStyles,
} from './quantityPreviewTable';
import ExportButton from '$gcomponents/reusables/exportButton';
import Div from '$gstyles/div';
import { FACTORY_TYPE } from '$fbusiness/enums/options/factoryType';

import SampleItemsBakery from '../importItemModal/sample_items.json';
import SampleItemsLs from '../importItemModal/sample_items_ls.json';
import { getExportSetting } from '../importItemModal/previewTable';
import { sampleInventory } from './sample_inventory';
import { SPACE } from '$gstyles';

interface ImportItemModalProps {
  factory: FactoryModel;
  refreshTable?: Function;
  show: boolean;
  onClose: Function;
}

const ORIGINAL_TABS: Array<OptionModel> = [
  { label: 'BUTTON.IMPORT_ITEMS', value: 0 },
  { label: 'BUTTON.IMPORT_PHOTOS', value: 1 },
];

const ImportItemModal: React.FC<ImportItemModalProps> = ({ factory, show, onClose, refreshTable }) => {
  const [newItems, setNewItems] = useState<Array<any>>([]);
  const [fields, setFields] = useState<Array<string>>([]);
  const [inventory, setInventory] = useState<Array<any>>([]);
  const [photos, setPhotos] = useState<Array<any>>([]);
  const [mode, setMode] = useState(0);
  const photoMode = mode === 1;
  const inputMode = photoMode
    ? !photos || photos.length === 0
    : mode === 0
    ? !newItems || newItems.length === 0
    : !inventory || inventory.length === 0;
  const dispatch = useDispatch();
  const { readString } = usePapaParse();
  const exportSetting = getExportSetting(factory);
  const { warehouses } = factory;

  const TABS = factory.settings?.inventory
    ? [...ORIGINAL_TABS, { label: 'BUTTON.IMPORT_QUANTITY', value: 2 }]
    : ORIGINAL_TABS;

  const maxSize = 10000000;
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize,
    accept: {
      'text/csv': [],
    },
    onDrop: (acceptedFiles: any) => {
      if (!acceptedFiles.length) {
        dialog.alert({
          text: intl('ERROR.CSV_FILE'),
        });
        return;
      }
      const reader = new FileReader();
      reader.readAsText(acceptedFiles[0]);
      reader.onload = () => {
        const csvContent: any = reader.result;
        readString(csvContent.toString(), {
          worker: true,
          header: true,
          skipEmptyLines: true,
          complete: async (result) => {
            const groomed = result.data.map((line) => {
              const row: any = line;
              const acc: any = {};
              const terms = ['cost', 'price'];
              if (!row) return {};
              for (const key in row) {
                const trimmed = row[key].trim();
                const lowerKey = key.toLowerCase();
                if (terms.some((term) => lowerKey.includes(term))) {
                  acc[key] = trimmed.replace('$', '');
                } else {
                  acc[key] = trimmed;
                }
              }
              return acc;
            });
            if (result && result.data && result.data.length) {
              if (mode === 0) {
                setNewItems(groomed);
                return;
              }
              // Inventory quantity import
              const fields = (result.meta.fields || []).splice(2);
              setFields(fields);
              if (!fields || !fields.length) {
                fileError();
                return;
              }
              // Good inventory file but still need to validate
              const ids = groomed.reduce((acc, row): any => (row.id ? [...acc, row.id] : acc), []);

              await dispatch(itemActions.fetchItemsSimple({ ids: JSON.stringify(ids) })).then((res) => {
                const data = groomed.map((row) => {
                  const item = res.find((item) => item.id === Number(row.id || '0'));
                  let error: any = null;
                  if (!row.id) {
                    if (!error) error = {};
                    error.id = 'Invalid ID';
                  }
                  if (!item || row.ITEMS !== item?.name) {
                    if (!error) error = {};
                    error.ITEMS = item?.name || '';
                  }
                  fields.forEach((field) => {
                    const qty = Number(row[field]);
                    if (warehouses?.findIndex((wh) => wh.short === field) === -1) {
                      if (!error) error = {};
                      error[field] = intl('ERROR.INVALID_WAREHOUSE');
                    } else if (!(Number.isInteger(qty) && qty >= 0)) {
                      if (!error) error = {};
                      error[field] = intl('ERROR.INVALID_QUANTITY');
                    }
                  });
                  return {
                    ...row,
                    error,
                    quantity:
                      Math.round(
                        fields.reduce((acc, field) => {
                          return acc + Number(row[field]);
                        }, 0) * 10,
                      ) / 10,
                  };
                });
                setInventory(data);
                return;
              });
              return;
            } else fileError();
          },
        });
      };
    },
  });

  const fileError = () => {
    dialog.alert({
      text: intl('ERROR.CSV_FILE_CORRUPT'),
    });
  };

  const { getRootProps: photoProps, getInputProps: photoInputProps } = useDropzone({
    maxFiles: 1000,
    maxSize,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    onDrop: async (acceptedFiles: any) => {
      if (!acceptedFiles.length) {
        dialog.alert({
          text: intl('ERROR.IMAGE_FILE'),
        });
        return;
      }
      setPhotos(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            file: file,
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const onSubmitInventory = async () => {
    const filtered = inventory.filter(validateRowInventory);
    const param = filtered.map((row) => {
      const { id, quantity, ITEMS, ...distributions } = row;
      return {
        id,
        distributions: Object.entries(distributions).map(([short, quantity]) => ({
          short,
          quantity,
        })),
      };
    });
    await dispatch(itemActions.importInventory(param, fields));
    if (refreshTable) refreshTable();
    else dispatch(initActions.fetchMyFactory(true));
    onClose();
  };

  const onSubmitImport = async () => {
    const filtered = newItems.filter(validateRow);
    const param = filtered.map((row) => ({
      id: row.id || 0,
      sku: row.sku,
      barcode: row.barcode,
      tax: row.tax,
      name: row.itemName,
      quantity: row.quantity,
      retailPrice: row.retailPrice.replace('$', ''),
      wholesalePrice: row.wholesalePrice.replace('$', ''),
      rawCost: row.rawCost.replace('$', ''),
      ...(row.dept && { dept: row.dept }),
      ...(row.ebrochure && { ebrochure: row.ebrochure }),
      ...(row.promo && { promo: row.promo }),
      ...(row.packing && { packing: row.packing }),
      ...(row.skid && { skid: row.skid }),
      ...(row.lotNo && { lotNo: row.lotNo }),
      ...(row.binNo && { binNo: row.binNo }),
      category: row.category,
      ...(row.subcategory && { subcategory: row.subcategory }),
    }));
    await dispatch(itemActions.importItems(param));
    if (refreshTable) refreshTable();
    else dispatch(initActions.fetchMyFactory(true));
    onClose();
  };

  const onSubmitPhotos = async () => {
    const param = photos.map((row) => row.file);
    await dispatch(itemActions.importPhotos(param));
    if (refreshTable) refreshTable();
    else dispatch(initActions.fetchMyFactory(true));
    onClose();
  };

  const onCloseModal = () => {
    setNewItems([]);
    onClose();
  };

  const onReset = () => {
    setNewItems([]);
    setPhotos([]);
    setInventory([]);
  };

  const sampleItemFile = factory?.type === FACTORY_TYPE.ECOMMERCE ? SampleItemsLs : SampleItemsBakery;
  const sampleFile = mode === 0 ? sampleItemFile : sampleInventory(warehouses);
  const sampleFileName = mode === 0 ? 'sample_items' : 'sample_inventory';

  const isDisabled =
    mode === 2 && (!inventory || !inventory.length || inventory.some((row) => !validateRowInventory(row)));

  return (
    <ModalWrapper
      width="1000px"
      title={photoMode ? 'MESSAGE.IMPORT_PHOTO' : 'MESSAGE.IMPORT_ITEM'}
      show={show}
      onClose={onCloseModal}
      useCustom>
      <IonContent>
        <Flex className="body">
          <TabSimple index={mode} TABS={TABS} onChangeIndex={setMode} />
          <div className="tab-content">
            {inputMode ? (
              photoMode ? (
                <div className="input-mode">
                  <div {...photoProps({ className: 'dropzone photo' })}>
                    <input {...photoInputProps()} />
                    <p>{intl('MESSAGE.IMPORT_ITEM_PHOTO')}</p>
                  </div>
                </div>
              ) : (
                <div className="input-mode">
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>{intl('MESSAGE.IMPORT_ITEM_LABEL')}</p>
                  </div>
                  <Div className="center" paddingTop={SPACE.LARGE}>
                    <ExportButton
                      component={<Link>{intl('MESSAGE.DOWNLOAD_SAMPLE_CSV')}</Link>}
                      data={sampleFile}
                      fileName={sampleFileName}
                      exportSetting={exportSetting}
                    />
                  </Div>
                </div>
              )
            ) : photoMode ? (
              <div className="data-mode">
                <div className="chunks">
                  {photos.map((photo, i) => (
                    <div className="chunk" key={i}>
                      <img src={photo.preview} alt={photo.file.name} />
                      <div className="caption">{photo.file.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : mode === 0 ? (
              <div className="data-mode">
                <Table styles={previewStyles} TABLE={PREVIEW_TABLE(factory)} data={newItems} />
              </div>
            ) : (
              <div className="data-mode">
                <Table
                  styles={quantityPreviewStyles}
                  TABLE={QUANTITY_PREVIEW_TABLE(fields)}
                  data={inventory}
                />
              </div>
            )}
          </div>
        </Flex>
      </IonContent>
      <Footer justifyContent="space-around">
        {!inputMode ? (
          <>
            <Button startIcon={<ArrowBack />} variant="outlined" color="secondary" onClick={onReset}>
              {intl('BUTTON.IMPORT_RESET')}
            </Button>
            <Button
              className="third"
              disabled={isDisabled}
              onClick={photoMode ? onSubmitPhotos : mode === 0 ? onSubmitImport : onSubmitInventory}>
              {intl('BUTTON.SUBMIT')}
            </Button>
          </>
        ) : (
          <Button className="third" onClick={onCloseModal} variant="outlined">
            {intl('BUTTON.CANCEL')}
          </Button>
        )}
      </Footer>
    </ModalWrapper>
  );
};

export default ImportItemModal;
