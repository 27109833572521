export const KEYS = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  OPEN_BALANCE: 'OPEN_BALANCE',
  ALMOST_DUE: 'ALMOST_DUE',
  OVERDUE: 'OVERDUE',
};

export const OPEN_FILTER = KEYS;

const convertToOption = (item) => ({
  label: `STORE_FILTER.${item}`,
  value: item,
});

const LIST = Object.keys(KEYS);

export const STORE_FILTER_LIST = LIST;

export const STORE_FILTER_OPTIONS = LIST.map(convertToOption);
