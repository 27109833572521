import React from 'react';
import intl from '$intl';
import { Flex } from '$gstyles/wrapper';
import { Autocomplete } from '$gcomponents/reusables';
import { Div, SPACE } from '$gstyles';
import { getPublicFactoryId } from '$gbusiness/helpers/util';
import { configs } from '$configs';
import { Button } from '$gcomponents/primitives';
import { getEl } from '$gbusiness/helpers/input';
import { IonIcon } from '@ionic/react';
import { barcodeOutline } from 'ionicons/icons';
import { deriveRawToItem, initialItem } from '$fbusiness/models/item';

interface ItemPickerProps {
  scanMode: boolean;
  disabled: boolean;
  onClickScan: Function;
  onScanFetch: Function;
  onSelectItem: Function;
  enableBlank?: boolean;
  storeId?: number;
}

export const AUTO_COMPLETE = (onClickItem) => ({
  method: 'POST',
  minChar: 2,
  renderSuggestion: (e) => {
    return (
      <Flex justifyContent="space-between">
        {e.sku == null ? (
          <>
            <Div marginLeft={SPACE.SMALL}>{e.name} </Div>
            {e.id ? (
              <Button
                className="primary filled"
                styles={{ backgroundColor: '#000' }}
                size="small"
                color="secondary"
                onClick={() => {
                  /*onClickItem(e)*/
                }}>
                {intl('SCREEN.PO.BUTTON.ADD')}
              </Button>
            ) : null}
          </>
        ) : (
          <>
            <div>{`${e.name} (${e.sku}) ${e.barcode && ` - ${e.barcode}`}`}</div>
            {e.outOfStock ? (
              <div>{intl('COMMON.OUT_OF_STOCK')}</div>
            ) : (
              <Button
                className="primary filled"
                styles={{ backgroundColor: '#000' }}
                size="small"
                color="secondary"
                onClick={() => {
                  /*onClickItem(e)*/
                }}>
                {intl('SCREEN.PO.BUTTON.ADD')}
              </Button>
            )}
          </>
        )}
      </Flex>
    );
  },
  getLabel: (obj) => obj.name || '',
  onEnter: () => {
    // FOR SCAN. ADD ITEM
  },
  deriveRaw: (response) => {
    if (!response) return [];
    if (response.items) return [...response.items.map(deriveRawToItem)];
    return [];
  },
});

const ItemPicker: React.FC<ItemPickerProps> = ({
  disabled,
  scanMode,
  onScanFetch,
  onSelectItem,
  onClickScan,
  enableBlank = false,
  storeId = 0,
}) => {
  const handleClickScan = () => {
    focusOnSearchBar();
    onClickScan();
  };
  const focusOnSearchBar = () => {
    const el = getEl('.searchInput .react-autosuggest__input');
    if (el) el.focus();
  };
  return (
    <div className={disabled ? 'o50 readonly' : ''}>
      <Flex justifyContent="space-between">
        <Flex className="searchInput" flex={7}>
          <Autocomplete
            hideSearchIcon
            position="top"
            autoSelect
            throttle={200}
            fixedList={
              enableBlank
                ? [
                    { ...initialItem, id: 0, itemId: 0, name: 'BLANK' },
                    { ...initialItem, id: 0, itemId: 0, name: 'TEXT' },
                  ]
                : []
            }
            endpoint={scanMode ? configs.api.item.scan : configs.api.item.autocomplete}
            placeholder={intl('INPUT.PLACEHOLDER.SEARCH_ITEM')}
            AC={{
              ...AUTO_COMPLETE(onSelectItem),
              ...(scanMode && {
                onResponse: onScanFetch,
                clearOnFetch: true,
                method: 'GET',
              }),
              clearOnSelect: true,
              extraParam: {
                factoryId: getPublicFactoryId(),
                withSerial: true,
                itemOnly: true,
                storeId,
                startsWith: true,
              },
              onSelect: onSelectItem,
            }}
            height="40px"
            width="calc(100% - 80px)"
          />
          <Button onClick={focusOnSearchBar}>{intl('SCREEN.PO.BUTTON.SEARCH')}</Button>
        </Flex>
        <Div className="scan-barcode" flex={3}>
          <Button onClick={handleClickScan} className="barcode-button">
            <IonIcon icon={barcodeOutline} size="large" />
            {intl('SCREEN.PO.BUTTON.SCAN')}
          </Button>
        </Div>
      </Flex>
    </div>
  );
};

export default ItemPicker;
