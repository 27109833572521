import CellModel from '$gbusiness/models/cell';
import { COLORS, SORT } from '$gbusiness/enums';
import { defaultStyles } from '$gbusiness/models/table';
import { requiredCell } from './previewTable';
import intl from '$gintl';

export const quantityPreviewStyles = {
  ...defaultStyles,
  responsive: true,
  color: COLORS.SECONDARY,
  fontSize: '1.05em',
  minWidth: 800,
  rowHeight: 32,
  headerHeight: 50,
  maxWidth: 1200,
  cellPadding: ` 8px 4px`,
  isStripped: false,
  borderColor: 'var(--ion-border-color)',
  disableSort: true,
  setRowClass: (row) => {
    if (!validateRow(row)) return 'invalid';
    return row.id ? 'update' : 'new';
  },
};

export const validateRow = (row) => {
  const { ITEMS, quantity, id, ...rest } = row;
  if (rest.error) return false;
  return true;
  // if (!id || !ITEMS) return false;
  // for (var i in rest) {
  //   if (isNaN(rest[i])) return false;
  // }
  // return true;
};

export const QUANTITY_PREVIEW_TABLE = (warehouses): Array<CellModel> => [
  {
    label: 'SCREEN.ITEMS.COLS.ID',
    value: 'id',
    component: (row) => <div className={`${row.error?.id ? 'error' : ''}`}>{row.id || 'INVALID'}</div>,
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.NAME',
    value: 'ITEMS',
    className: 'main',
    component: (row) => (
      <>
        <div className={`${row.error?.ITEMS ? 'error' : ''}`}>{requiredCell(row['ITEMS'])}</div>
        {row.error?.ITEMS && <div className="disabled">{row.error.ITEMS}</div>}
      </>
    ),
    width: 350,
  },
  ...(warehouses || [])?.map((warehouse, i) => ({
    labelText: warehouse,
    value: warehouse,
    align: 'center',
    component: (row) => {
      const error = row.error ? row.error[warehouse] : '';
      return (
        <div className={!!error ? 'error' : ''}>
          {error && error === intl('ERROR.INVALID_WAREHOUSE')
            ? row.error[warehouse]
            : (row[warehouse], row[warehouse] || null)}
        </div>
      );
    },
    sortable: SORT.ASC,
    width: 60,
  })),
  {
    label: 'SCREEN.ITEMS.COLS.QUANTITY_SUM',
    value: 'quantity',
    align: 'center',
    className: 'desktop',
    component: (row) => {
      return row.quantity;
    },
    sortable: SORT.ASC,
    width: 120,
  },
];

export default QUANTITY_PREVIEW_TABLE;
