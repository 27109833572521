import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { Button, Text } from '$gcomponents/primitives';
import { AppForm } from '$styles/general';
import { factoryActions } from '$fbusiness/redux/factory';
import { AppModel } from '$business/redux/';

import { configs } from '$configs';
import { IonContent } from '@ionic/react';
import Footer from '$gcomponents/widgets/footer';
import WarehouseModel from '$fbusiness/models/warehouse';
import { WAREHOUSE_FORM } from './warehouseForm';

interface WarehouseDetailsProps {
  warehouse: WarehouseModel;
  onClose: Function;
  refreshList: Function;
}

const WarehouseDetails: React.FC<WarehouseDetailsProps> = ({ warehouse, onClose, refreshList }) => {
  const dispatch = useDispatch();
  const isFinished = useSelector((state: AppModel) => state.factory.isFinished);
  const formRef = useRef<any>();

  useEffect(() => {
    if (!isFinished) return;
    dispatch(factoryActions.resetFinished());
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished, dispatch, onClose]);

  if (!warehouse) return null;
  const { id, address, ...initialValues } = warehouse;

  const onSubmit = (values) => {
    const newValues = {
      ...values,
    };

    dispatch(
      factoryActions.saveObj(
        id || 0,
        newValues,
        configs.api.warehouse.general,
        factoryActions.fetchWarehouses,
      ),
    );
  };

  const validateForm = (values) => {
    return input.validateError(WAREHOUSE_FORM, values);
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={
        id ? { ...initialValues, address: address?.formatted || '', address_place: address } : initialValues
      }
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <div className="content">
              <AppForm>
                <FormSection FORM={WAREHOUSE_FORM} formik={formik} />
              </AppForm>
            </div>
          </IonContent>
          <Footer justifyContent="center">
            <Button className="half" onClick={formik.handleSubmit} disabled={!formik.isValid}>
              <Text k="BUTTON.SAVE" />
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default WarehouseDetails;
