import React from 'react';
import { ModalWrapper } from './styles';
import { itemText } from '$gbusiness/helpers/util';
import StoreLevelDetails from './storeLevelDetails';
import StoreLevelModel from '$fbusiness/models/storeLevel';

interface StoreLevelEditModalProps {
  storeLevel: StoreLevelModel;
  onClose: Function;
}

const StoreLevelEditModal: React.FC<StoreLevelEditModalProps> = ({ storeLevel, onClose }) => {
  const titleText = itemText(storeLevel?.id ? 'EDIT' : 'CREATE', 'STORE_LEVEL');

  return (
    <ModalWrapper show={!!storeLevel} onClose={() => onClose()} titleText={titleText} useCustom>
      {storeLevel && <StoreLevelDetails storeLevel={storeLevel} onClose={onClose} />}
    </ModalWrapper>
  );
};

export default StoreLevelEditModal;
