import { Div, FONT, SPACE, WEIGHT } from '$gstyles';
import { flex, vcenter } from '$gstyles/mixins';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .table-tabs {
    .MuiTab-root {
      /* border-radius: 0; */
    }
    .MuiTab-root:not(.Mui-selected) {
      background-color: #eee;
      border-color: #fff;
    }
  }
  .table-tabs.outlined {
    .MuiTab-root:not(.Mui-selected) {
      background-color: initial;
    }
  }

  .MuiTableHead-root {
    border-bottom: 1px solid #ccc;
    .MuiTableRow-head {
      height: 60px;
    }
    .MuiTableCell-root {
      font-weight: ${WEIGHT.SEMI_BOLD};
      font-size: inherit;
    }
  }
  .MuiTableCell-root.first {
    border-left: 1px solid var(--ion-border-color);
  }
  .MuiTableCell-root.last {
    border-right: 1px solid var(--ion-border-color);
  }
  .MuiBreadcrumbs-ol {
    text-align: left;
  }
  .crumb.current {
    color: #000000;
  }
  .item-actions {
    button {
      padding: 0;
    }
  }
`;

export const FilterWrapper = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -${SPACE.LARGE} 0 ${SPACE.LARGE} 0;

  .MuiOutlinedInput-root {
    background-color: transparent;

    .MuiIconButton-root {
      color: var(--ion-color);
    }
  }
`;

export const SummarySection = styled.div`
  ${flex}
  text-align: center;
  max-width: 800px;
  height: 120px;

  > div {
    flex-basis: 25%;
  }
`;
export const SummayrIconWrapper = styled.div`
  ${vcenter};
  border: 1px solid rgba(0, 0, 0, 0.33);
  color: #bbb;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &.active {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast-bw);
  }

  margin: 2%;
  .MuiSvgIcon-root {
    font-size: 48px;
  }
  .title {
    font-size: ${FONT.XLARGE};
  }
  .subtitle {
    font-size: 1.3rem;
  }
`;
