import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';

export const STORE_LEVEL_FORM = (roles): Array<InputRowModel> => [
  {
    items: [
      {
        label: 'SCREEN.STORE_LEVELS.COLS.NAME',
        value: 'name',
        input: INPUT.TEXT,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.STORE_LEVELS.COLS.LEVEL',
        value: 'level',
        input: INPUT.NUMBER,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.PERCENT,
        label: 'SCREEN.STORE_LEVELS.COLS.RATE',
        value: 'discountRate',
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.STORE_LEVELS.COLS.FLAT',
        value: 'discountFlat',
        input: INPUT.CURRENCY,
        gridSize: 6,
      },
    ],
  },
];
