const sampleItems = [
  'Bleach',
  '6" Clearseal Dart Hinged Container',
  '9" Clearseal Dart Hinged Container',
  '12" Food Service Film',
  '16oz PET Clear Cups VG',
];

export const sampleInventory = (warehouses) => {
  return sampleItems.map((ITEMS, i) => {
    const id = i + 1000;

    const warehouseCols = warehouses.map((warehouse) => ({
      [warehouse.short]: Math.floor(Math.random() * 200),
    }));

    return {
      id,
      ITEMS,
      ...Object.assign({}, ...warehouseCols),
    };
  });
};
