export interface ItemPricing {
  id?: number;
  name: string;
  storeLevelId: number;
  level: number;
  expectedPrice?: number;
  price: number | null;
}

export const deriveRawToItemPricing = (raw) => {
  return {
    id: raw.id || 0,
    name: raw?.storeLevel?.name || '',
    storeLevelId: raw?.storeLevel?.storestoreLevelId,
    level: raw?.storeLevel?.level,
    price: raw.price,
  };
};

export const deriveEmptyItemPrices = (storeLevels) => {
  return storeLevels.map((level) => ({
    name: level.name,
    level: level.level,
    storeLevelId: level.id,
    price: null,
  }));
};

export const deriveExpectedPrice = (retailPrice, storeLevel) => {
  if (storeLevel.discountFlat > 0) return retailPrice - storeLevel.discountFlat;
  if (storeLevel.discountRate > 0) return retailPrice - retailPrice * (storeLevel.discountRate / 100);
  return retailPrice;
};
