export const deriveWarehouseDistributions = (warehouses, item) => {
  return warehouses.map((warehouse) => {
    const data = (item.distributions || []).find((d) => d.warehouseId === warehouse.id);
    if (data) return data;
    return {
      id: 0,
      itemId: item.id,
      quantity: 0,
      warehouseId: warehouse.id,
      warehouse: warehouse.name,
    };
  });
};

export const deriveShippingDistribution = (item, warehouses, warehouseId, qty) => {
  if (!item?.distributions?.length) return [];
  return warehouses.map((warehouse) => {
    const itemDist = item.distributions.find((d) => d.warehouseId === warehouse.id);
    return {
      ...warehouse,
      id: 0,
      warehouseId: warehouse.id,
      itemId: item.id,
      quantity: warehouse.id === warehouseId ? qty : 0,
      maxQty: itemDist?.quantity || 0,
    };
  });
};
