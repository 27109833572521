import React from 'react';
import intl from '$intl';
import { OrderSummaryWrapper } from './styles';
import { currency } from '$gbusiness/helpers/util';

interface OrderSummaryProps {
  price: any;
  isHidden?: boolean;
  includeTitle?: boolean;
  isCharge?: boolean;
  showDetails?: boolean;
  onEdit?: Function;
  editables?: any;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  price,
  onEdit = null,
  showDetails = false,
  isHidden = false,
  isCharge = false,
  includeTitle,
  editables,
}) => {
  if (isHidden) return null;
  const {
    subtotal,
    itemDiscount,
    totalDiscount,
    discount: passedDiscount,
    itemTaxable,
    total,
    tax,
    delivery,
    shipping,
    taxable,
    refund = null,
    refundAmt,
    tip,
    invoiceTotal,
    isCredit,
    isRefund,
    totals,
  } = price;

  const discount = showDetails
    ? totalDiscount - itemDiscount || passedDiscount || 0
    : totalDiscount || passedDiscount || 0;
  const invoiceChargeSum = totals?.invoiceChargeSum || 0;

  const finalTotal = (invoiceTotal || total) + invoiceChargeSum;
  const refundTotal = refund?.totals?.finalTotal || refund?.total;

  const checkEditable = (key: string) => {
    if (!editables) return false;
    return editables.hasOwnProperty(key);
  };

  const onChangeEditable = (e, key) => {
    if (!onEdit) return;
    let value = e.target.value;
    if (value < 0) value = 0;
    onEdit({ ...editables, [key]: Number(value) });
  };

  return (
    <OrderSummaryWrapper>
      {includeTitle && <div className="title">{intl('SCREEN.ORDER.SUMMARY.TITLE')}</div>}
      <div className="box">
        {!isCharge && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.SUBTOTAL')}</div>
            <div className="value">{currency(subtotal || 0)}</div>
          </div>
        )}

        {showDetails && itemDiscount > 0 && (
          <>
            <div className="line">
              <div className="label">{intl('SCREEN.ORDER.SUMMARY.ITEM_DISCOUNT')}</div>
              <div className="value success outlined">-{currency(itemDiscount || 0)}</div>
            </div>
            <div className="line">
              <div className="label">{intl('SCREEN.ORDER.SUMMARY.ITEM_TAXABLE')}</div>
              <div className="value">{currency(itemTaxable || 0)}</div>
            </div>
          </>
        )}
        {discount > 0 && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.DISCOUNT')}</div>
            <div className="value success outlined">-{currency(discount || 0)}</div>
          </div>
        )}
        {shipping > 0 && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.SHIPPING')}</div>
            {onEdit ? (
              <div className="value">
                <input value={shipping} type="number" onChange={(e) => onChangeEditable(e, 'shipping')} />
              </div>
            ) : (
              <div className="value">{currency(shipping || 0)}</div>
            )}
          </div>
        )}
        {(discount > 0 || shipping > 0) && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.TAXABLE')}</div>
            <div className="value">{currency(taxable || 0)}</div>
          </div>
        )}
        {(delivery > 0 || checkEditable('delivery')) && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.DELIVERY')}</div>
            {onEdit ? (
              <div className="value">
                <input value={delivery} type="number" onChange={(e) => onChangeEditable(e, 'delivery')} />
              </div>
            ) : (
              <div className="value">{currency(delivery || 0)}</div>
            )}
          </div>
        )}
        {(tip > 0 || checkEditable('tip')) && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.TIP')}</div>
            {onEdit ? (
              <div className="value">
                <input value={tip} onChange={(e) => onChangeEditable(e, 'tip')} />
              </div>
            ) : (
              <div className="value">{currency(tip || 0)}</div>
            )}
          </div>
        )}
        {(!isCharge || checkEditable('tax')) && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.TAX')}</div>
            {onEdit ? (
              <div className="value">
                <input value={tax} onChange={(e) => onChangeEditable(e, 'tax')} />
              </div>
            ) : (
              <div className="value">{currency(tax || 0)}</div>
            )}
          </div>
        )}
        {invoiceChargeSum > 0 && (
          <div className="line">
            <div className="label">{intl('COMMON.INVOICE_CHARGE')}</div>
            <div className="value">{currency(invoiceChargeSum || 0)}</div>
          </div>
        )}
        {!(isRefund || isCredit) ? (
          <>
            {refundAmt > 0 ? (
              <>
                <div className="line">
                  <div className="label">{intl('SCREEN.ORDER.SUMMARY.REFUND')}</div>
                  <div className="value success outlined">-{currency(refundAmt || 0)}</div>
                </div>
                <div className="line total">
                  <div className="label">{intl('SCREEN.ORDER.SUMMARY.TOTAL')}</div>
                  <div className="value">{currency(finalTotal - refundAmt || 0)}</div>
                </div>
              </>
            ) : (
              <div className="line total">
                <div className="label">{intl('SCREEN.ORDER.SUMMARY.TOTAL')}</div>
                <div className="value">{currency(finalTotal || total || 0)}</div>
              </div>
            )}
            {refund && (
              <div className="line" style={{ marginTop: '20px' }}>
                <div className="label">{intl('SCREEN.ORDER.SUMMARY.REFUND')}s</div>
                <div className="value danger outlined">{currency(refundTotal || 0)}</div>
              </div>
            )}
          </>
        ) : (
          <div className="line total">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.TOTAL')}</div>
            <div className="value">{currency(finalTotal || 0)}</div>
          </div>
        )}
      </div>
    </OrderSummaryWrapper>
  );
};

export default OrderSummary;
