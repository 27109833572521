import React from 'react';
import intl from '$intl';
import { OrderSummaryWrapper } from './styles';
import { currency } from '$gbusiness/helpers/util';

interface InvoicePaymentSummaryProps {
  invoicePayment: any;
  invoiceRefundSum?: number;
  refundCredit?: number;
  refunds?: Array<any>;
  isEdit?: boolean;
}

const InvoicePaymentSummary: React.FC<InvoicePaymentSummaryProps> = ({
  invoicePayment,
  invoiceRefundSum = 0,
  refundCredit,
  refunds = [],
  isEdit = false,
}) => {
  const { balance, creditAmount, paidAmount, paymentDiscount, totals } = invoicePayment;
  const finalBalance = isEdit ? balance : totals?.finalBalance || balance;
  const hasRefunds = refunds?.length > 0;
  return (
    <OrderSummaryWrapper>
      <div className="box">
        {/* <div className="line">
          <div className="label">{intl('SCREEN.INVOICES.COLS.TOTAL')}</div>
          <div className="value">{currency(total || 0)}</div>
        </div> */}
        {paidAmount !== undefined && (
          <div className="line">
            <div className="label">{intl('SCREEN.INVOICES.COLS.PAID_AMOUNT')}</div>
            <div className="value">-{currency(paidAmount || 0)}</div>
          </div>
        )}
        {paymentDiscount !== undefined && (
          <div className="line">
            <div className="label">{intl('SCREEN.INVOICE_PAYMENT.DISCOUNT_AMOUNT')}</div>
            <div className="value">-{currency(paymentDiscount || 0)}</div>
          </div>
        )}
        {creditAmount !== undefined && (
          <div className="line">
            <div className="label">{intl('SCREEN.INVOICE_PAYMENT.CREDIT_AMOUNT')}</div>
            <div className="value">-{currency(creditAmount || 0)}</div>
          </div>
        )}
        {invoiceRefundSum !== undefined && (
          <div className="line">
            <div className="label">{intl('SCREEN.INVOICE_PAYMENT.SUMMARY.REFUNDS')}</div>
            <div className="value">{currency(invoiceRefundSum || 0)}</div>
          </div>
        )}
        {refundCredit !== undefined && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.REFUND_CREDITS')}</div>
            <div className="value">-{currency(refundCredit || 0)}</div>
          </div>
        )}
        <div className="line total">
          <div className="label">{intl('SCREEN.INVOICES.COLS.BALANCE')}</div>
          <div className="value">{currency(finalBalance || 0)}</div>
        </div>
      </div>

      {hasRefunds && (
        <>
          <div className="line" style={{ marginTop: '20px' }}>
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.REFUND')}s</div>
            <div className="value danger outlined">{currency(refunds.reduce((a, b) => a + b.rcApplied))}</div>
          </div>
          <div className="line total">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.BALANCE')}</div>
            <div className="value">{currency(finalBalance || 0)}</div>
          </div>
        </>
      )}
      {/* <Col gridSize={7} className="refund-table">
          {hasRefunds && (
            <div>
              <h3>Refunds</h3>
              {refunds.map((r, i) => (
                <Table key={i} {...tableProps} TABLE={REFUND_ITEMS_TABLE()} data={r.items} />
              ))}
            </div>
          )}
        </Col> */}
    </OrderSummaryWrapper>
  );
};

export default InvoicePaymentSummary;
