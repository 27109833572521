import React, { useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import { Header, TableView2 } from '$gcomponents/widgets';

import USER_TABLE, { USER_TABLE_CONFIG } from './table';

import { IonPageWrapper } from './styles';
import PATH from '$gbusiness/enums/paths';
import FabButton from '$fcomponents/fabButton';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import StoreFilter from '$fcomponents/storeFilter';
import FactoryModel from '$fbusiness/models/factory';
import StoreModel from '$fbusiness/models/store';
import { accessHide, getAccess } from '$fbusiness/helpers/util';
import CurrentStateModel from '$fbusiness/models/currentState';
interface StoresScreenProps {
  history: any;
  factory: FactoryModel;
  currentState: CurrentStateModel;
  stores: Array<StoreModel>;
}

const UsersScreen: React.FC<StoresScreenProps> = ({ stores, currentState, factory }) => {
  const [filter, setFilter] = useState<any>({});
  const ACCESS = getAccess(currentState);

  const onChangeStore = (result) => {
    if (result) {
      setFilter({
        storeUser: !!result,
        storeId: result ? result.id : null,
      });
    } else {
      setFilter({});
    }
  };

  const isStoreMode = filter?.storeId;
  const titleText = filter.storeUser ? 'Store Users' : 'Factory Users';

  return (
    <IonPageWrapper>
      <Header titleText={titleText} />
      <TableView2 TABLE={USER_TABLE} filter={filter} tableConfig={USER_TABLE_CONFIG}>
        <FilterSection title={null}>
          <StoreFilter stores={stores} onChange={onChangeStore} />
        </FilterSection>
      </TableView2>
      <FabButton
        route={`${PATH.USERS}/0${isStoreMode ? `/${filter.storeId}` : ''}`}
        className={accessHide(isStoreMode ? ACCESS.ACTION.USER.CREATE : ACCESS.ACTION.USER.CREATE)}
      />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  stores: state.store.stores,
  resetOnRoute: true,
});

const connected = connect(mapStateToProps, null);

export default connected(screen(UsersScreen));
