import styled from 'styled-components';
import { IonMenu } from '@ionic/react';

import { configs } from '$configs';
import { FONT, SPACE, WEIGHT } from '$gstyles';

export const Wrapper = styled(IonMenu)`
  ${(p) =>
    p.hide
      ? `
  @media screen and (min-width: ${configs.display.desktopMinThreshold}) {
    --side-max-width: 60px;
    --side-min-width: 60px;
  } `
      : `
  @media screen and (min-width: ${configs.display.desktopMinThreshold}) {
    --side-max-width: 200px;
    --side-min-width: 200px;
  }
`}
  transition: max-width 0.2s ease-out, min-width 0.2s ease-out;

  .outer {
    min-width: 200px;
  }
  ion-label {
    opacity: 1;
    transition: opacity 0.3s;
  }
  .hide-pane {
    ion-label {
      opacity: 0;
    }
    .accordion-children {
      display: none !important;
    }
  }

  border-width: 0px;

  ion-content,
  ion-footer,
  ion-list,
  #background-content,
  ion-list-header,
  ion-item,
  button,
  ion-toolbar,
  .menu-item a,
  div {
    border-color: var(--ion-color);
    --background: var(--ion-color-medium);
    color: var(--ion-color);
    font-weight: ${WEIGHT.NORMAL};
  }

  ion-item {
    height: 32px;
  }
  ion-item::part(native) {
    margin-top: -5px;
    min-height: ${SPACE.LARGE};
    font-size: ${FONT.SMALL};
  }
  .list-md {
    background: var(--ion-color-medium);
    color: var(--ion-color);
  }
  ion-item.item ion-label,
  .menu-item a,
  ion-item.item .MuiSvgIcon-root {
    &.empty {
      min-width: ${configs.theme?.hideIcon ? '4px' : '24px'};
    }
    font-weight: ${WEIGHT.NORMAL};
  }
  ion-item.item:hover {
    --background: var(--ion-color-medium);

    ion-label {
      color: var(--ion-color);
      font-weight: ${WEIGHT.NORMAL};
    }
  }
  ion-item.selected {
    --background: var(--ion-background-color);
  }
  a.selected {
    background-color: var(--ion-background-color);
  }
  ion-menu-toggle.sub-category {
    ion-label.hydrated {
      font-size: 0.9em;
      opacity: 0.8;
    }
  }

  .MuiSvgIcon-root {
    margin-top: ${SPACE.MEDIUM};
    margin-bottom: ${SPACE.MEDIUM};
    margin-inline-end: ${SPACE.LARGE};
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .MuiAccordion-root .MuiAccordionSummary-root.Mui-expanded {
    min-height: initial;
    /* pointer-events: none; */
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .MuiPaper-root {
    background-color: inherit;
    font-size: 1.4rem;
    ion-item {
      pointer-events: auto;
    }
    .MuiAccordionSummary-root {
      padding: 0 ${SPACE.SMALL};
      padding-left: ${SPACE.MEDIUM};
    }
    .MuiAccordionDetails-root,
    .menu-item > div {
      padding: 0;
    }
    .menu-item > div a {
      display: block;
      padding: ${SPACE.MEDIUM};
      padding-left: 20px;
    }
    ion-list {
      padding: 0;
      width: 100%;
    }
  }
  .MuiAccordion-root {
    box-shadow: none;
    ion-item {
      border-top: none;
    }
    .MuiAccordionSummary-expandIcon {
      padding: 0 12px;
      margin-right: 4px;
      width: 32px;
      pointer-events: auto;
    }
    .MuiAccordionSummary-root {
      padding: 0;
      min-height: initial;
      .MuiAccordionSummary-content {
        margin: 0;
      }
    }
    .MuiAccordionSummary-root.Mui-focusVisible {
      background-color: var(--ion-color-background) !important;
    }
    .MuiAccordionDetails-root {
      display: block;
    }
    .MuiSvgIcon-root {
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
  .MuiAccordion-root:before {
    background-color: transparent;
  }

  .pop-button-container {
    margin: ${SPACE.MEDIUM};
    display: block;
  }

  .logo {
    display: inline-block;
    padding: 8px 16px;
    height: 52px;
  }

  .app-version {
    display: inline-block;
    height: ${(props) => (props.isProd ? '14' : '28')}px;
    position: absolute;
    padding: ${SPACE.MEDIUM};
    right: ${SPACE.SMALL};
    font-size: 1.15rem;
    opacity: 0.5;
  }

  &.md {
    ion-note {
      padding-left: ${SPACE.MEDIUM};
    }
    ion-item {
      --padding-start: ${SPACE.MEDIUM};
      --padding-end: ${SPACE.MEDIUM};
      /* border-radius: 4px; */
    }

    .MuiSvgIcon-root {
      color: var(--ion-color);
    }

    ion-item ion-label {
    }
  }
  .user {
    text-align: center;
    margin-top: ${SPACE.MEDIUM};
    .name {
      font-size: 1em;
    }
    .email {
      opacity: 0.7;
      font-size: 0.9em;
    }
  }

  .arrow-hide {
    background: var(--ion-color-medium);
    color: var(--ion-color-primary-bk);
    border-radius: 10px 0 0 10px;
    border: 1px solid #999;
    position: relative;
    opacity: 0.75;
    right: -3px;
    width: 24px;
    top: -20px;
    padding: 40px 5px;
    cursor: pointer;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2);
    svg {
      color: #fff;
      font-size: 1.6rem;
    }
  }
`;
