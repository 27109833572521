import React from 'react';
import ModifierGroupModel from '$fbusiness/models/modifierGroup';
import { ModalWrapper } from './styles';

import ModifierGroupDetails from './modifierGroupDetails';

interface ModifierGroupModalProps {
  modifierGroup?: ModifierGroupModel;
  index: number;
  onClose: Function;
}

const ModifierGroupModal: React.FC<ModifierGroupModalProps> = ({ modifierGroup, index, onClose }) => {
  return (
    <ModalWrapper useCustom title="SCREEN.MODIFIERS.TITLE" onClose={() => onClose()} show={!!modifierGroup}>
      {modifierGroup && (
        <ModifierGroupDetails modifierGroup={modifierGroup} index={index} onClose={onClose} />
      )}
    </ModalWrapper>
  );
};

export default ModifierGroupModal;
