import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { deriveRawToSerial, initialSerial } from '../../models/serial';
import {
  SerialActionTypes,
  FETCH_SERIAL_SUCCESS,
  CLEAN_SERIAL,
  UPDATE_SERIAL_SUCCESS,
  SERIAL_FAILURE,
  DELETE_SERIAL_SUCCESS,
  FETCH_SERIALS_SUCCESS,
} from './types';
import { LOADED } from '$gbusiness/redux/loading/types';

export function fetchSerials({
  itemId = '',
  simple = '',
  statuses = [],
  invoiceItemId = 0,
  warehouseId = null,
}): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url:
        configs.api.batch.serial +
        `?itemId=${itemId}&simple=${simple}` +
        (statuses.length ? `&statuses=${JSON.stringify(statuses)}` : '') +
        (invoiceItemId ? `&invoiceItemId=${invoiceItemId}` : '') +
        (warehouseId ? `&warehouseId=${warehouseId}` : ''),
      method: 'GET',
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, SERIAL_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    if (simple) {
      dispatch({ type: LOADED });
      return Promise.resolve(response.list);
    }
    dispatch({
      type: FETCH_SERIALS_SUCCESS,
      serials: response.list.map((d) => deriveRawToSerial(d)),
    });
  };
}

export function fetchSerial(serialId): any {
  return async (dispatch: Dispatch) => {
    if (!serialId || isNaN(serialId)) {
      dispatch({
        type: FETCH_SERIAL_SUCCESS,
        serial: initialSerial,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.batch.serial + '/' + serialId,
      method: 'GET',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, SERIAL_FAILURE, response, 'ERROR.SAVE', true);
      return;
    }

    dispatch({
      type: FETCH_SERIAL_SUCCESS,
      serial: deriveRawToSerial(response.data),
    });
  };
}

export function saveSerials({ serials, itemId, batchId, warehouseId }): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.batch.serial,
      param: {
        ...(itemId && { itemId }),
        ...(batchId && { batchId }),
        ...(warehouseId && { warehouseId }),
        serials,
      },
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, SERIAL_FAILURE, response, 'ERROR.SAVE', true);
      return Promise.resolve(false);
    } else {
      const numSuccess = response?.count || 0;
      const message = numSuccess
        ? `${numSuccess} serials saved`
        : 'No serial added. Either empty or duplicates';
      handleApiSuccess(dispatch, UPDATE_SERIAL_SUCCESS, message, 'small', numSuccess < 1);
      return Promise.resolve(true);
    }
  };
}

export function updateSerial(serial): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.batch.serial + '/' + serial.id,
      method: 'PUT',
      param: serial,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, SERIAL_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      return Promise.resolve(true);
    }
  };
}

export function addSerialScan(param): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.batch.serialScanAdd,
      method: 'POST',
      param,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, SERIAL_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, null, 'MESSAGE.SERIAL_ADDED');
      return Promise.resolve(true);
    }
  };
}

export function deleteSerials(serialIds): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.batch.serialsDelete,
      method: 'POST',
      param: { serialIds },
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, SERIAL_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_SERIAL_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
      return Promise.resolve(true);
    }
  };
}

export function deleteSerial(serialId): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.batch.serial + (serialId ? '/' + serialId : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, SERIAL_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_SERIAL_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
      return Promise.resolve(true);
    }
  };
}

export function dehydrate(): SerialActionTypes {
  return { type: CLEAN_SERIAL };
}
