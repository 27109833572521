import styled from 'styled-components';
import { Div, FONT, SPACE, WEIGHT } from '$gstyles';
import { Modal } from '$gcomponents/reusables';
import { mobileStyle } from '$gstyles/utils';

export const Wrapper = styled(Div)`
  .MuiTab-root {
    background-color: #f6f6f6;
  }

  .MuiTableRow-head {
    height: 54px;
    .MuiTableCell-head {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
  }

  .MuiTable-root {
    .MuiSelect-select {
      font-size: ${FONT.SMALL};
    }
  }
`;

export const ModalWrapper = styled(Modal)`
  .export-wrapper {
    text-align: right;
    > div {
      display: inline-block;
    }
  }
  .MuiTableCell-head {
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
  .right {
    justify-content: right;
    text-align: right;
  }
  input.qty,
  .subtotal-input input {
    width: 60px;
    text-align: center;
    border-radius: 4px;
    border-width: 1px;
  }
  .subtotal-input input {
    width: 70px;
  }
  .subtotal-input:before {
    content: '$';
    left: 2px;
  }
  .content-wrapper {
    padding: ${SPACE.XLARGE};
  }
  ${mobileStyle(`
    .content-wrapper {
      padding: ${SPACE.MEDIUM};
    }
  `)}

  .flag-message {
    padding-bottom: ${SPACE.XLARGE};
  }

  .notes-label {
    font-weight: ${WEIGHT.SEMI_BOLD};
    margin: ${SPACE.LARGE} 0 ${SPACE.MEDIUM} ${SPACE.SMALL};
  }
  textarea {
    resize: none;
    width: 100%;
    height: 80px;
    padding: ${SPACE.MEDIUM};
  }

  .fromto {
    .title {
      font-weight: ${WEIGHT.SEMI_BOLD};
      padding: ${SPACE.MEDIUM} 0;
      text-transform: uppercase;
    }
    .info {
    }
    margin-bottom: ${SPACE.LARGE};
  }

  .shipping-dist {
    justify-content: space-between;
    .qty {
      width: 50px;
      margin-left: ${SPACE.XSMALL};
    }
  }

  .summary-wrapper {
    width: 240px;
  }

  .total-section {
    padding: ${SPACE.LARGE};
    justify-content: flex-end;
    .label {
      width: 100px;
      text-align: center;
    }
    .value {
      width: 80px;
      text-align: right;
    }
  }

  .line {
    display: flex;
    justify-content: flex-end;
    height: 24px;
    max-width: 300px;
    .label {
      width: 110px;
      padding: ${SPACE.SMALL};
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .value {
      width: 110px;
      padding: ${SPACE.SMALL};
    }
  }

  .info-container {
    padding: ${SPACE.LARGE};
    font-size: ${FONT.MEDIUM};

    > div {
      align-items: flex-start;
      margin: ${SPACE.LARGE} ${SPACE.SMALL};
    }
    .label {
      width: 40%;
    }
    .value {
      width: 60%;
    }
  }

  @media print {
    .col {
      width: inherit;
    }
  }
`;
