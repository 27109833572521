import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';

import { SPACE } from '$gstyles';
import { input } from '$gbusiness/helpers';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { useDispatch } from 'react-redux';
import { serialActions } from '$fbusiness/redux/serial';
import { ScanModalWrapper } from './styles';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import ItemModel from '$fbusiness/models/item';
import { Form, Formik } from 'formik';
import { SERIAL_SCAN_FORM } from './serialForm';
import { FormSection } from '$gcomponents/reusables';
import { getAccess, isAccessible } from '$fbusiness/helpers/util';
import CurrentStateModel from '$fbusiness/models/currentState';

const { debounce } = _;

interface SerialScanModalProps {
  show: boolean;
  item?: ItemModel;
  items?: Array<ItemModel>;
  currentState: CurrentStateModel;
  onClose: Function;
  onRefresh: Function;
}

const SerialScanModal: React.FC<SerialScanModalProps> = ({
  show,
  currentState,
  items,
  item,
  onRefresh,
  onClose,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef<any>();
  const inputRef = useRef<any>(null);

  const [skipScan, setSkipScan] = useState(true);
  const [serialNumber, setSerialNumber] = useState('');
  const ACCESS = getAccess(currentState);

  useEffect(() => {
    if (!show) return;
    setTimeout(() => {
      resetModal();
    }, 500);
  }, [show]);

  const serialForm = SERIAL_SCAN_FORM({ items });

  const resetModal = () => {
    setSerialNumber('');
    if (inputRef.current) setTimeout(() => inputRef.current.focus(), 500);
  };

  const onLookupSerial = (value, sc) => {
    if (value?.length > 4) {
      if (!formRef.current.values?.item || !sc) {
        formRef.current.setFieldTouched('item', true);
        formRef.current.setFieldError('item', intl('ERROR.REQUIRED'));
        return;
      }

      setTimeout(() => {
        formRef.current.submitForm(value);
      }, 100);

      return;
    }
  };

  const onChangeSerial = (value) => {
    setSerialNumber(value);
    if (!formRef.current.values?.item) {
      formRef.current.setFieldTouched('item', true);
      formRef.current.setFieldError('item', intl('ERROR.REQUIRED'));
    }
    debouncedSearch(value, skipScan);
  };

  const debouncedSearch = useCallback(debounce(onLookupSerial, 700), []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (values) => {
    const { item } = values;

    const param = {
      serial: serialNumber,
      itemId: item?.id,
    };

    dispatch(serialActions.addSerialScan(param)).then((success) => {
      if (success) {
        if (onRefresh) onRefresh();
        resetModal();
      }
    });
  };

  const validateForm = (values) => {
    return input.validateError(serialForm, values);
  };

  const initialValues = {
    ...(item && { item }),
  };

  const title = 'Add Serial by Scan';

  return (
    <ScanModalWrapper width="500px" titleText={title} show={show} onClose={() => onClose()} useCustom>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => {
          const isFormValid = formik.isValid && serialNumber?.length > 4;
          return (
            <>
              <IonContent>
                <PageWrapper>
                  <Form>
                    <FormSection
                      className="item-input-wrapper"
                      FORM={serialForm}
                      formik={formik}
                      marginBottom="30px"
                    />
                  </Form>
                  <input
                    type="text"
                    placeholder="Scan or Enter Serial Number..."
                    className="scan-input"
                    ref={inputRef}
                    onChange={(e) => onChangeSerial(e.target.value)}
                    value={serialNumber}
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch checked={skipScan} onChange={() => setSkipScan(!skipScan)} />}
                      label="Add immediately without confirmation"
                    />
                  </FormGroup>
                </PageWrapper>
              </IonContent>
              <IonFooter>
                <Flex padding={SPACE.MEDIUM} justifyContent="space-around">
                  <Button className="third" onClick={() => onClose()} variant="outlined">
                    {intl('BUTTON.CANCEL')}
                  </Button>
                  <Button
                    className="third"
                    disabled={!isFormValid || !isAccessible(ACCESS.ACTION.SERIAL.CREATE, currentState)}
                    onClick={formik.handleSubmit}>
                    {intl('BUTTON.SUBMIT')}
                  </Button>
                </Flex>
              </IonFooter>
            </>
          );
        }}
      </Formik>
    </ScanModalWrapper>
  );
};

export default SerialScanModal;
