import React, { useEffect, useRef, useState } from 'react';
import intl from '$intl';
import { Formik } from 'formik';
import { IonContent, IonFooter } from '@ionic/react';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { SPACE } from '$gstyles';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { Modal } from '$gcomponents/reusables';
import { BATCH_FORM } from './batchForm';
import FactoryModel from '$fbusiness/models/factory';
import ItemModel from '$fbusiness/models/item';
import { getNextIdF } from '$fbusiness/redux/factory/actions';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { itemActions } from '$fbusiness/redux/item';
import { itemText, toNumber } from '$gbusiness/helpers/util';
import { dateToString } from '$gbusiness/helpers/date';
import { batchActions } from '$fbusiness/redux/batch';
import ItemBatchModel, { initialBatch } from '$fbusiness/models/itemBatch';
import { parseISO } from 'date-fns/esm';
import { accessDisable, getAccess } from '$fbusiness/helpers/util';
import CurrentStateModel from '$fbusiness/models/currentState';

interface BatchModalProps {
  factory: FactoryModel;
  item?: ItemModel;
  batch?: ItemBatchModel;
  isItemPage?: boolean;
  show: boolean;
  currentState: CurrentStateModel;
  onRefresh?: Function;
  readOnly?: boolean;
  onClose: Function;
}

const BatchModal: React.FC<BatchModalProps> = ({
  factory,
  item,
  batch = initialBatch,
  show,
  currentState,
  readOnly = false,
  isItemPage,
  onRefresh,
  onClose,
}) => {
  const dispatch = useDispatch();
  const items = useSelector((state: any) => state.item.list);
  const formRef = useRef<any>();
  const [loop, setLoop] = useState<any>(null);
  const ACCESS = getAccess(currentState);

  const { vendors, settings } = factory;
  const batchForm = BATCH_FORM({
    vendors,
    warehouses: factory.warehouses,
    items: items || [],
    settings,
    isEdit: !!batch?.id,
    disabled: readOnly,
    requireSerial: item?.settings?.requireSerial,
  });

  useEffect(() => {
    if (!show) {
      if (loop) setLoop(null);
      return;
    }
    setTimeout(checkReceivingNo, 600);
    setLoop(setInterval(checkReceivingNo, 10000));
    if (!items.length) {
      dispatch(itemActions.fetchItemList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const checkReceivingNo = () => {
    if (batch?.id) return;
    if (formRef?.current && !formRef.current.values?.receiveNo) {
      getNextIdF('item_batches', 'receiveNo').then(async (id) => {
        if (formRef?.current) formRef.current.setFieldValue('receiveNo', id);
      });
    }
  };

  const onSubmit = (values) => {
    const { item, serials, cost, expirationDate, receiveDate, sellByDate, ...rest } = values;
    const param = {
      ...rest,
      itemId: item?.id || 0,
      cost: toNumber(cost),
      expirationDate: dateToString(expirationDate),
      receiveDate: dateToString(receiveDate),
      sellByDate: dateToString(sellByDate),
      serials: batch.id ? undefined : (serials || '').trim().split(/[\n,]/),
    };

    dispatch(batchActions.saveBatch(batch?.id, param)).then((success) => {
      if (success) {
        if (onRefresh) onRefresh();
        if (isItemPage && item?.id) dispatch(itemActions.fetchItem(item?.id));
        onClose();
      }
    });
  };

  const validateForm = (values) => {
    return input.validateError(batchForm, values);
  };

  const initialValues = {
    item,
    ...(batch?.id
      ? {
          ...batch,
          ...(batch.receiveDate && { receiveDate: parseISO(batch.receiveDate) }),
          ...(batch.sellByDate && { sellByDate: parseISO(batch.sellByDate) }),
          ...(batch.expirationDate && { expirationDate: parseISO(batch.expirationDate) }),
          ...(batch.warehouse && { warehouseId: batch.warehouse.id }),
          serials: batch.serials.join('\n'),
        }
      : { receiveDate: new Date() }),
  };

  const title = batch?.id ? itemText('EDIT', 'BATCH') : itemText('ADD_NEW', 'BATCH');
  const disableClass = accessDisable(
    batch?.id ? ACCESS.ACTION.RECEIVING.EDIT : ACCESS.ACTION.RECEIVING.CREATE,
  );

  return (
    <Modal width="600px" titleText={title} show={show} onClose={() => onClose()} useCustom>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContent>
              <PageWrapper>
                <FormSection FORM={batchForm} formik={formik} marginBottom="0" />
                {batch?.id > 0 && (
                  <div className="serials">
                    <h4>Serials</h4>
                    {batch?.serials.map((s, i) => (
                      <div className="serial" key={i}>
                        {s.serial}
                      </div>
                    ))}
                  </div>
                )}
              </PageWrapper>
            </IonContent>
            {
              <IonFooter>
                <Flex padding={SPACE.MEDIUM} justifyContent="flex-end">
                  <Button
                    disabled={!(formik.isValid && formik.dirty)}
                    className={disableClass}
                    onClick={formik.handleSubmit}>
                    {intl('BUTTON.SUBMIT')}
                  </Button>
                </Flex>
              </IonFooter>
            }
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default BatchModal;
