import { Modal } from '$gcomponents/reusables';
import { FONT } from '$gstyles';
import styled from 'styled-components';

export const DistributionsWrapper = styled.div`
  .warehouse-row {
    .name {
      padding-right: 10px;
      max-width: 200px;
      min-width: 100px;
    }
    .qty-input {
      width: 100px;
    }
    display: flex;
    align-items: center;
    padding: 3px;
    justify-content: space-between;

    .actions {
      > span {
        display: flex;
      }
      display: flex;
    }
    .edit-icon {
      font-size: ${FONT.LARGE};
    }
    .transfer-icon {
      font-size: ${FONT.XLARGE};
      margin-right: 10px;
    }
  }
`;

export const DistributionModalWrapper = styled(Modal)`
  .MuiFormControl-root {
    min-width: 150px;
  }
  .qty-input {
    width: 100px;
    padding: 10px;
  }
  .memo-input {
    padding-left: 10px;
    width: 250px;
    padding-bottom: 10px;
  }
`;
