import React from 'react';
import CellModel from '$gbusiness/models/cell';
import intl from '$intl';
import ExportModel from '$gbusiness/models/export';
import { configs } from '$configs';
import { deriveRawToItem } from '$fbusiness/models/item';
import { COLORS, SORT } from '$gbusiness/enums';
import { defaultStyles } from '$gbusiness/models/table';
import { FACTORY_TYPE } from '$fbusiness/enums/options/factoryType';

export const getExportSetting = (factory): ExportModel => {
  const { type: factoryType, settings, taxes } = factory;
  const mainTax = taxes.find((t) => true);
  return {
    url: configs.api.item.general,
    method: 'GET',
    deriveToModel: deriveRawToItem,
    deriveTable: (item) => ({
      id: item.id,
      sku: item.sku,
      // upc: item.upc,
      barcode: item.barcode,
      itemName: item.name,
      tax: !item.tax?.id ? '0' : item.tax?.id === mainTax?.id ? '1' : '',
      retailPrice: item.retailPrice,
      wholesalePrice: item.wholesalePrice,
      rawCost: item.rawCost,
      quantity: item.quantity,
      ...(factoryType === FACTORY_TYPE.ECOMMERCE && { packing: item.settings?.packing || '' }),
      ...(factoryType === FACTORY_TYPE.ECOMMERCE && { skid: item.settings?.skid || '' }),
      ...(factoryType === FACTORY_TYPE.ECOMMERCE && { promo: item.settings?.promo ? 1 : '' }),
      ...(factoryType === FACTORY_TYPE.ECOMMERCE && { lotNo: item.settings?.lotNo || '' }),
      ...(factoryType === FACTORY_TYPE.ECOMMERCE && { binNo: item.settings?.binNo || '' }),
      ...(settings?.ebrochure && { ebrochure: item.ebrochure ? 1 : '' }),
      ...(factoryType === FACTORY_TYPE.BAKERY && { dept: item.depts.map((i) => i.name).join() }),
      category: item.categories.map((i) => i.name).join(),
    }),
    sortKey: 'rank',
    sortOrder: SORT.ASC,
  };
};

export const previewStyles = {
  ...defaultStyles,
  responsive: true,
  color: COLORS.SECONDARY,
  fontSize: '1.05em',
  minWidth: 800,
  rowHeight: 44,
  headerHeight: 50,
  maxWidth: 1200,
  cellPadding: ` 8px 4px`,
  isStripped: false,
  borderColor: 'var(--ion-border-color)',
  disableSort: true,
  setRowClass: (row) => {
    if (!validateRow(row)) return 'invalid';
    return row.id ? 'update' : 'new';
  },
};

export const validateRow = (row) => {
  const { wholesalePrice, retailPrice, itemName, category } = row;
  if (isNaN(wholesalePrice) || isNaN(retailPrice) || !itemName || !category) return false;
  return true;
};

export const requiredCell = (value) => {
  return value ? <>{value}</> : <div className="error">*REQUIRED*</div>;
};

export const requiredNumber = (oValue, validDisplay) => {
  if (!oValue) return <div className="error">*REQUIRED*</div>;
  const value = oValue.replace('$', '');
  return isNaN(value) ? <div className="error">{value}</div> : <>{validDisplay}</>;
};

export const PREVIEW_TABLE = (factory): Array<CellModel> => [
  {
    label: 'SCREEN.ITEMS.COLS.ID',
    value: 'id',
    component: (row) => row.id || 'NEW',
    width: 100,
  },
  {
    label: 'SCREEN.ITEMS.COLS.NAME',
    value: 'itemName',
    className: 'main',
    component: (row) => requiredCell(row.itemName),
    width: 250,
  },
  {
    label: 'SCREEN.ITEMS.COLS.UPC',
    value: 'barcode',
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.SKU',
    value: 'sku',
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.RETAIL_PRICE',
    value: 'retailPrice',
    align: 'center',
    component: (row) => requiredNumber(row.retailPrice, intl('PRICE', { amount: row.retailPrice })),
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.TAX',
    value: 'tax',
    align: 'center',
    component: (row) => (row.tax === '0' ? 'No Tax' : row.tax === '1' ? 'Main Tax' : ''),
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.PRICE',
    value: 'wholesalePrice',
    align: 'center',
    component: (row) => requiredNumber(row.wholesalePrice, intl('PRICE', { amount: row.wholesalePrice })),
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.RAW_COST',
    value: 'rawCost',
    align: 'center',
    component: (row) => requiredNumber(row.rawCost, intl('PRICE', { amount: row.rawCost })),
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.QUANTITY',
    value: 'quantity',
    align: 'center',
    component: (row) => <>{row.quantity == null ? 'Unlimited' : row.quantity}</>,
    width: 120,
  },
  {
    isHidden: !factory.settings?.ebrochure,
    label: 'SCREEN.ITEMS.COLS.EBROCHURE',
    value: 'ebrochure',
    align: 'center',
    width: 120,
  },
  {
    isHidden: factory.type !== FACTORY_TYPE.ECOMMERCE,
    label: 'SCREEN.ITEMS.COLS.PROMO',
    value: 'promo',
    align: 'center',
    className: 'word-break',
    width: 60,
  },
  {
    isHidden: factory.type !== FACTORY_TYPE.ECOMMERCE,
    label: 'SCREEN.ITEMS.COLS.PACKING',
    value: 'packing',
    align: 'center',
    width: 120,
  },
  {
    isHidden: factory.type !== FACTORY_TYPE.ECOMMERCE,
    label: 'SCREEN.ITEMS.COLS.SKID',
    value: 'skid',
    align: 'center',
    width: 120,
  },
  {
    isHidden: factory.type !== FACTORY_TYPE.ECOMMERCE,
    label: 'SCREEN.ITEMS.COLS.LOTNO',
    value: 'binNo',
    align: 'center',
    width: 120,
  },
  {
    isHidden: factory.type !== FACTORY_TYPE.ECOMMERCE,
    label: 'SCREEN.ITEMS.COLS.BINNO',
    value: 'lotNo',
    align: 'center',
    width: 120,
  },
  {
    isHidden: factory.type !== FACTORY_TYPE.BAKERY,
    label: 'SCREEN.ITEMS.COLS.DEPT',
    value: 'dept',
    className: 'desktop',
    component: (row) => requiredCell(row.dept),
    width: 200,
  },
  {
    label: 'SCREEN.ITEMS.COLS.CATEGORY',
    value: 'category',
    className: 'vertical',
    component: (row) => {
      const el = (
        <div>
          {row.category}
          {row.subcategory && <span> &raquo; {row.subcategory}</span>}
        </div>
      );
      return requiredCell(el);
    },
    align: 'center',
    width: 200,
  },
];

export default PREVIEW_TABLE;
