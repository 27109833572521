import styled from 'styled-components';
import { FONT, SPACE, WEIGHT } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';

export const Wrapper = styled.div`
  .MuiTableCell-head {
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
  .qty {
    width: 60px;
    text-align: center;
    border-radius: 4px;
    border-width: 1px;
  }
  .content-wrapper {
    padding: ${SPACE.XLARGE};
  }
  ${mobileStyle(`
  .content-wrapper {
    padding: ${SPACE.MEDIUM};
  }
`)}

  .description {
    opacity: 0.75;
    font-size: 0.85em;
  }

  .serial-list {
    font-size: ${FONT.XSMALL};
    font-family: courier;
    opacity: 0.8;
  }
  .invoice-info.row {
    width: 100%;
    .col {
      width: ${(p) => (p.hasShipping ? '25%' : '33%')}px;
    }
  }

  .note-wrapper {
    flex: 1;
    > div {
      padding: ${SPACE.LARGE};
      border: 1px solid #ccc;
      border-radius: 7px;
      font-weight: 600;
      margin: 8px 0;
    }
  }
  .notes-label {
    font-weight: ${WEIGHT.SEMI_BOLD};
    margin: ${SPACE.LARGE} 0 ${SPACE.MEDIUM} ${SPACE.SMALL};
  }
  textarea {
    resize: none;
    width: 100%;
    height: 120px;
    padding: ${SPACE.MEDIUM};
  }

  .fromto {
    .title {
      font-weight: ${WEIGHT.SEMI_BOLD};
      padding: ${SPACE.MEDIUM} 0;
      text-transform: uppercase;
    }
    .info {
    }
    margin-bottom: ${SPACE.LARGE};
  }

  .summary-wrapper {
    width: 300px;
  }

  .line {
    display: flex;
    justify-content: flex-end;
    height: 24px;
    max-width: 300px;
    .label {
      width: 110px;
      padding: ${SPACE.SMALL};
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .value {
      width: 136px;
      padding: ${SPACE.SMALL};
    }
  }

  .info-container {
    padding: ${SPACE.LARGE};
    font-size: ${FONT.MEDIUM};

    > div {
      align-items: flex-start;
      margin: ${SPACE.SMALL};
    }
    .label {
      width: 40%;
    }
    .value {
      width: 60%;
    }
  }

  .terms {
    margin-top: ${SPACE.MEDIUM};
    font-size: 0.9em;
    opacity: 0.9;
  }

  @media print {
    font-size: 0.9em;
    .first-two {
    }
    .order-section {
      width: 33%;
    }
    .invoice-info.row {
      font-size: 0.85em;
    }
    .invoice-info,
    .order-section {
      margin-top: 4px !important;
    }
    .fromto {
      .title {
        padding: 0;
      }
    }
    .line {
      height: auto;
      .label,
      .value {
        padding: 0;
      }
    }
    .note-wrapper {
      > div {
        margin-top: 0px;
      }
    }
    .summary-wrapper {
      width: 230px;
    }
    .table-wrapper {
      margin-top: 10px !important;
      .MuiTableRow-head {
        height: 36px;
      }
      .MuiTableCell-root {
        min-height: 12px;
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .blank-row {
        height: 16px;
        font-weight: ${WEIGHT.SEMI_BOLD};
      }
    }
    .invoice-info > .col {
      width: 50%;
    }
    .invoice-info > .col:first-child {
      width: 50%;
    }
    .col {
      width: inherit;
    }
  }
`;
