import React from 'react';
import { IonSelect, IonSelectOption, IonItem, IonLabel } from '@ionic/react';

import intl, { Text } from '$gintl';
import StoreModel from '$fbusiness/models/store';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { StoreSelectorWrapper } from '../styles';

interface StoreSelectorProps {
  onChange: (e) => void;
  stores: Array<StoreModel>;
  values: any;
  className?: string;
}

const STORES_THRESHOLD = 1000;

const StoreSelector: React.FC<StoreSelectorProps> = ({ className = '', onChange, stores, values }) => {
  if (stores.length > STORES_THRESHOLD) return null;

  const onChangeCheck = (e) => {
    if (!e.target.checked) {
      onChange(values || []);
      return;
    }
    onChange(null);
  };

  return (
    <StoreSelectorWrapper className={className}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={values === null} onChange={onChangeCheck} />}
          label={intl('INPUT.LABEL.ALL_STORES')}
        />
      </FormGroup>
      {values !== null && (
        <IonItem className="store-selector">
          <IonLabel position="stacked">
            <Text k="INPUT.LABEL.SELECT_STORES" />
          </IonLabel>
          <IonSelect
            value={values}
            multiple={true}
            onIonChange={(e) => {
              onChange(e.detail.value);
            }}>
            {stores.map((s, i) => (
              <IonSelectOption value={s.id} key={i}>
                {s.name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      )}
    </StoreSelectorWrapper>
  );
};

export default StoreSelector;
