import React, { useEffect, useState } from 'react';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';

import { SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import DistributionItemModel from '$fbusiness/models/distributionItem';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DistributionModalWrapper } from './styles';
import Div from '$gstyles/div';
import { isWholeNumber } from '$gbusiness/helpers/util';

interface TransferModalProps {
  onClose: Function;
  warehouses: Array<any>;
  distribution: DistributionItemModel;
  onSubmit: Function;
}

const TransferModal: React.FC<TransferModalProps> = ({ distribution, onClose, onSubmit, warehouses }) => {
  const [destinationIndex, setDestinationIndex] = useState<any>(-1);
  const [quantity, setQuantity] = useState<any>('');
  const [memo, setMemo] = useState<any>('');

  useEffect(() => {
    if (!distribution?.warehouseId) return;
    setQuantity('');
    setMemo('');
    setDestinationIndex(-1);
  }, [distribution?.warehouseId]);

  const destination = destinationIndex >= 0 ? warehouses[destinationIndex] : null;
  const isFormValid =
    destination && isWholeNumber(quantity, true) && distribution?.quantity >= Number(quantity);

  const onChangeDestination = (e) => {
    const newIndex = warehouses.findIndex((w) => w.warehouseId === e.target.value);
    setDestinationIndex(newIndex);
  };

  const onSubmitCheck = (qty) => {
    const { itemId } = distribution;
    const param = {
      quantity: Number(qty),
      fromId: distribution.warehouseId,
      toId: destination.warehouseId,
      itemId,
      memo,
    };
    onSubmit(param);
  };

  return (
    <DistributionModalWrapper
      width="400px"
      height="340px"
      title="SCREEN.WAREHOUSES.TITLE_TRANSFER"
      show={!!distribution}
      onClose={() => onClose()}
      useCustom>
      <IonContent>
        {distribution && (
          <div className="center">
            <Flex paddingTop={SPACE.LARGE} justifyContent="center">
              <FormControl variant="outlined" size="small">
                <InputLabel id="source-warehouse">{intl('SCREEN.WAREHOUSES.FROM_WAREHOUSE')}</InputLabel>
                <Select
                  labelId="source-warehouse"
                  disabled
                  label={intl('SCREEN.WAREHOUSES.FROM_WAREHOUSE')}
                  value={distribution.warehouseId}
                  onChange={() => {}}>
                  {warehouses.map((w, i) => (
                    <MenuItem key={i} value={w.warehouseId}>
                      {w.warehouse}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" size="small">
                <InputLabel id="to-warehouse">{intl('SCREEN.WAREHOUSES.TO_WAREHOUSE')}</InputLabel>
                <Select
                  labelId="to-warehouse"
                  label={intl('SCREEN.WAREHOUSES.TO_WAREHOUSE')}
                  value={destination?.warehouseId}
                  onChange={onChangeDestination}>
                  {warehouses.map((w, i) => (
                    <MenuItem
                      key={i}
                      value={w.warehouseId}
                      disabled={w.warehouseId === distribution.warehouseId}>
                      {w.warehouse}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Flex>
            <Flex justifyContent="center">
              <div>Quantity: </div>
              <TextField
                className="qty-input"
                variant="outlined"
                type="number"
                size="small"
                value={quantity}
                placeholder={`Max: ${distribution.quantity}`}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </Flex>
            <Div padding={SPACE.MEDIUM} justifyContent="center">
              Memo:
              <TextField
                className="memo-input"
                rows={2}
                size="small"
                multiline
                variant="outlined"
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
              />
            </Div>
          </div>
        )}
      </IonContent>
      <IonFooter>
        <Flex padding={SPACE.MEDIUM} justifyContent="center">
          <Button onClick={() => onSubmitCheck(quantity)} disabled={!isFormValid}>
            {intl('BUTTON.SUBMIT')}
          </Button>
        </Flex>
      </IonFooter>
    </DistributionModalWrapper>
  );
};

export default TransferModal;
