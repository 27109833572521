export const KEYS = {
  OPEN: 'OPEN',
  PAYMENT_SENT: 'PAYMENT_SENT',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED',
  VOID_PAYMENT: 'VOID_PAYMENT',
  REFUNDED: 'REFUNDED',
  DELETE: 'DELETE',
};

export const INVOICE_STATUS = KEYS;

export const convertToOption = (item) => ({
  label: `INVOICE_STATUS.${item}`,
  value: item,
  disabled: item === KEYS.DELETE,
});

export const OPEN_KEYS = [KEYS.OPEN, KEYS.PAYMENT_SENT, KEYS.CANCELLED];
export const OPEN_OPTIONS = OPEN_KEYS.map(convertToOption);

export const CANCELLED_KEYS = [KEYS.OPEN, KEYS.CANCELLED];
export const CANCELLED_OPTIONS = CANCELLED_KEYS.map(convertToOption);

export const MOCK_INVOICE_KEYS = [KEYS.PAYMENT_SENT, KEYS.VOID_PAYMENT, KEYS.REFUNDED, KEYS.CANCELLED];
export const MOCK_INVOICE_OPTIONS = MOCK_INVOICE_KEYS.map(convertToOption);

export const VOID_PAYMENT_KEYS = [KEYS.OPEN, KEYS.CANCELLED, KEYS.VOID_PAYMENT, KEYS.PAYMENT_SENT];
export const VOID_PAYMENT_OPTIONS = VOID_PAYMENT_KEYS.map(convertToOption);

export const REFUNDED_KEYS = [KEYS.REFUNDED, KEYS.PAID];
export const REFUNDED_OPTIONS = REFUNDED_KEYS.map(convertToOption);

export const CLOSE_KEYS = [KEYS.OPEN, KEYS.VOID_PAYMENT, KEYS.PAID];
export const CLOSE_OPTIONS = CLOSE_KEYS.map(convertToOption);

export const VALID_INVOICES = [KEYS.OPEN, KEYS.PAYMENT_SENT, KEYS.VOID_PAYMENT, KEYS.PAID];

const LIST = Object.keys(KEYS);

export default LIST.map(convertToOption);

export type INVOICE_STATUS_TYPES = typeof LIST[number] | undefined;
