import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonHeader,
  IonToolbar,
  IonImg,
  IonFooter,
  IonFab,
} from '@ionic/react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { configs, features } from '$configs';
import { Text } from '$intl';

import { Wrapper } from './styles';
import { AppVersion } from '$gcomponents/reusables';
import { Div, SPACE } from '$gstyles';
import UserModel from '$gbusiness/models/user';
import SideMenuItem from './sideMenuItem';
import FactoryModel from '$fbusiness/models/factory';
import FactorySelector from './factorySelector';
import { useDispatch } from 'react-redux';
import { cuserActions } from '$gbusiness/redux/currentUser';
import CurrentStateModel from '$fbusiness/models/currentState';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { setStorageItem } from '$gbusiness/services/local.storage';

interface MenuProps extends RouteComponentProps {
  logo?: string;
  user: UserModel;
  factories?: Array<FactoryModel>;
  currentState?: CurrentStateModel;
  isLoggedIn: boolean;
  hideMenuPane: boolean;
  currentRoute: string;
  onLogout: Function;
}

const Menu: React.FC<MenuProps> = React.memo(
  ({ logo, factories, currentRoute, user, isLoggedIn, hideMenuPane, currentState, onLogout, ...rest }) => {
    const dispatch = useDispatch();
    const [hidePane, setHidePane] = useState(hideMenuPane);
    const onChangeFactory = async (e) => {
      await dispatch(cuserActions.editProfile({ ...user, factoryId: e.target.value }));
      window.location.reload();
    };

    const togglePane = async (show) => {
      setHidePane(show);
      await setStorageItem('sideMenuPane', show ? 'false' : 'true');
    };

    return (
      <Wrapper
        contentId="main"
        menuId="start"
        hide={hidePane}
        disabled={!isLoggedIn}
        isProd={process.env.REACT_APP_ENV === 'prod'}
        {...rest}>
        {configs.display.layout !== 'FIXED_HEADER' && (
          <IonHeader>
            <IonToolbar>
              <IonImg class="logo" src={logo} />
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent class={`outer-content ${hidePane ? 'hide-pane' : ''}`}>
          <IonList>
            <SideMenuItem currentRoute={currentRoute} currentState={currentState} />
            <IonItem lines="none" class="pointer" onClick={() => onLogout()} detail={false}>
              {configs.theme?.hideIcon ? <span className="MuiSvgIcon-root empty" /> : <ExitToAppIcon />}
              <IonLabel>
                <Text k="MENU.LOG_OUT" />
              </IonLabel>
            </IonItem>
          </IonList>
          <div className="user">
            <IonLabel>
              <div className="name">{`${user.firstName} ${user.lastName}`}</div>
              <div className="email">{user.email}</div>
            </IonLabel>
            {user.userLevel === 0 && (
              <Div padding={SPACE.LARGE}>
                <FactorySelector
                  factories={factories}
                  selectedId={user?.other?.factoryId || 0}
                  onChange={onChangeFactory}
                />
              </Div>
            )}
          </div>
        </IonContent>
        <IonFooter>
          <Div height="32px">
            <AppVersion oneLine />
          </Div>
        </IonFooter>
        {features.sidebarExpand && (
          <IonFab horizontal="end" vertical="center">
            {hidePane ? (
              <div className="arrow-hide" onClick={() => togglePane(false)}>
                <ArrowForwardIos />
              </div>
            ) : (
              <div className="arrow-hide" onClick={() => togglePane(true)}>
                <ArrowBackIos />
              </div>
            )}
          </IonFab>
        )}
      </Wrapper>
    );
  },
  (pp, np) => pp.currentRoute === np.currentRoute && pp.isLoggedIn === np.isLoggedIn,
);

export default withRouter(Menu);
