export default interface StoreLevelModel {
  id: number;
  name: string;
  level: number;
  discountRate?: any;
  discountFlat?: any;
}

export const deriveRawToStoreLevel = (raw) => {
  if (!raw) return undefined;
  return {
    id: raw.id,
    name: raw.name,
    level: raw.level,
    discountRate: raw.discountRate,
    discountFlat: raw.discountFlat,
  };
};

export const initialStoreLevel = {
  id: 0,
  name: '',
  level: 0,
  discountRate: 0,
  discountFlat: 0,
};
