import React from 'react';
import { connect } from 'react-redux';
import { IonContent, IonFooter, useIonViewWillLeave } from '@ionic/react';

import intl from '$intl';
import { screen } from '$fcomponents/hoc';
import { Div, SPACE } from '$gstyles';
import { Form, Formik } from 'formik';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import { input } from '$gbusiness/helpers';
import { Flex } from '$gstyles/wrapper';
import Header from '$components/header';
import { factoryActions } from '$fbusiness/redux/factory';
import FactoryModel from '$fbusiness/models/factory';

import PATH from '$business/enums/paths';
import { initActions } from '$business/redux/init';
import CurrentStateModel from '$fbusiness/models/currentState';
import { getAccessAdmin } from '$fbusiness/helpers/util';
import ACCESS_FORM, { convertFormToValue } from '../factoryDetailsScreen/accessForm';
import { IonPageWrapper } from '../factoryDetailsScreen/styles';

interface FactoryAccessScreenProps {
  history: any;
  currentState: CurrentStateModel;
  roles: any;
  myFactory: FactoryModel;
  saveFactory: Function;
  fetchMyFactory: Function;
}

const FactoryAccessScreen: React.FC<FactoryAccessScreenProps> = ({
  history,
  currentState,
  roles,
  saveFactory,
  myFactory: factory,
  fetchMyFactory,
}) => {
  useIonViewWillLeave(() => {});

  if (!factory) return <IonPageWrapper />;
  const ACCESS_ADMIN = getAccessAdmin();
  const { settings, factoryId } = factory;

  const onSubmit = async (values) => {
    await saveFactory(factoryId, values);
    await fetchMyFactory();
  };
  const closeScreen = () => {
    history.push(PATH.FACTORIES);
  };

  const accessActionForm = ACCESS_FORM(
    ACCESS_ADMIN.ACTION,
    roles.map((r) => ({ value: r.level, labelText: r.label })),
    'settings.ACCESS.ACTION',
    'ACTION',
  );
  const accessScreenForm = ACCESS_FORM(
    ACCESS_ADMIN.SCREEN,
    roles.map((r) => ({ value: r.level, labelText: r.label })),
    'settings.ACCESS.SCREEN',
    'SCREEN',
  );

  const validateForm = (values) => {
    return input.validateError(accessActionForm, values);
  };

  const initialSettings = {
    ...settings,
    ...(!settings.ACCESS && {
      ACCESS: {
        ...convertFormToValue(accessActionForm),
        ...convertFormToValue(accessScreenForm),
        MONEY: {
          VIEW: 4,
        },
      },
    }),
  };

  return (
    <IonPageWrapper>
      <Header title="MENU.SETTINGS_ACCESS" />
      <Formik
        enableReinitialize={true}
        initialValues={{ settings: initialSettings }}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContent>
              <Div padding={SPACE.LARGE} maxWidth="800px">
                <Form>
                  <FormSection title="SCREEN.FACTORY.ACCESS_SCREEN" FORM={accessScreenForm} formik={formik} />
                  <FormSection title="SCREEN.FACTORY.ACCESS_ACTION" FORM={accessActionForm} formik={formik} />
                </Form>
              </Div>
            </IonContent>
            <IonFooter>
              <Flex padding={SPACE.MEDIUM} justifyContent="space-between">
                <Button variant="outlined" onClick={closeScreen}>
                  {intl('BUTTON.CANCEL')}
                </Button>
                <Button
                  color="primary"
                  className="submit-button"
                  variant="contained"
                  onClick={formik.handleSubmit}>
                  {intl('BUTTON.SUBMIT')}
                </Button>
              </Flex>
            </IonFooter>
          </>
        )}
      </Formik>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  myFactory: state.factory.factory,
  roles: state.factory.roles,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: (params) => factoryActions.fetchFactory(parseInt(params.factoryId)),
  onDehydrate: factoryActions.dehydrate,
  fetchMyFactory: () => initActions.fetchMyFactory(true),
  saveFactory: factoryActions.saveFactory,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(FactoryAccessScreen));
