import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import { Header } from '$gcomponents/widgets';

import { IonPageWrapper } from './styles';
import FactoryModel from '$fbusiness/models/factory';
import SerialsSection from './section';
import FabButton from '$fcomponents/fabButton';
import { PageWrapper } from '$gstyles/wrapper';
import { FormSection } from '$gcomponents/reusables';
import ItemModel from '$fbusiness/models/item';
import { SERIAL_FILTER_FORM, displayItem } from './serialForm';
import { Formik } from 'formik';
import { toNumber } from '$gbusiness/helpers/util';
import { categoryActions } from '$fbusiness/redux/category';
import CategoryModel from '$fbusiness/models/category';
import { Button } from '$gcomponents/primitives';
import intl from '$gintl';
import { SERIAL_STATUS } from '$business/enums/enums';
import StoreModel from '$fbusiness/models/store';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import CurrentStateModel from '$fbusiness/models/currentState';
import { features } from '$configs';
import SerialScanModal from './serialScanModal';
import { QrCode } from '@mui/icons-material';

interface SerialsScreenProps {
  factory: FactoryModel;
  items: Array<ItemModel>;
  stores: Array<StoreModel>;
  categories: Array<CategoryModel>;
  currentState: CurrentStateModel;
  match;
}

const SerialsScreen: React.FC<SerialsScreenProps> = ({
  factory,
  stores,
  items = [],
  categories = [],
  currentState,
  match,
}) => {
  const childRef = React.useRef<any>();
  const formRef = React.useRef<any>();

  const serialStatusOptions = factory.enums[SERIAL_STATUS];
  const initialStatuses = serialStatusOptions.map((o) => o.value);

  const [item, setItem] = useState<any>(null);
  const [category, setCategory] = useState<any>(null);
  const [store, setStore] = useState<any>(null);
  const [showSerialScan, setShowSerialScan] = useState<any>(false);
  const [statuses, setStatuses] = useState<any>(initialStatuses);
  const [query, setQuery] = useState('');

  const { params } = match;
  const itemId = toNumber(params.itemId);

  useEffect(() => {
    if (!itemId || !items.length) return;
    setItem(itemId);
    formRef.current?.setFieldValue(
      'item',
      items.find((i) => i.id === itemId),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, items.length]);

  const onChangeItem = async ({ value }) => {
    setItem(value);
    //childRef.current?.refreshTable({ itemId: value?.id || undefined });
  };

  const onChangeCategory = async ({ value }) => {
    setCategory(value);
    //childRef.current?.refreshTable({ categoryId: value?.id || undefined });
  };

  const onChangeStore = async ({ value }) => {
    setStore(value);
    //childRef.current?.refreshTable({ categoryId: value?.id || undefined });
  };

  const onChangeQuery = async ({ value }) => {
    setQuery(value);
  };

  const onChangeStatuses = async (value) => {
    setStatuses(value);
  };

  const onSubmit = () => {
    childRef.current?.refreshTable({
      categoryId: category?.id || undefined,
      itemId: item?.id || undefined,
      storeId: store?.id || undefined,
      query: query || undefined,
      statuses: JSON.stringify(statuses) || undefined,
    });
  };

  const onClickStore = (row) => {
    formRef.current.setFieldValue('store', row);
    onChangeStore({ value: row });
    childRef.current?.refreshTable({ storeId: row?.id || undefined });
  };

  const onClickCategory = (row) => {
    const value = { id: row.id, name: row.name };
    formRef.current.setFieldValue('category', value);
    onChangeCategory({ value });
    childRef.current?.refreshTable({ categoryId: value?.id || undefined });
  };

  const onClickItem = (row) => {
    const value = { id: row.id, name: displayItem(row) };
    formRef.current.setFieldValue('item', value);
    onChangeItem({ value });
    childRef.current?.refreshTable({ itemId: value?.id || undefined });
  };

  const onRefresh = () => {
    childRef.current?.refreshTable({});
  };

  const filterForm = SERIAL_FILTER_FORM({
    items,
    stores,
    serialStatusOptions,
    onChangeItem,
    categories,
    onChangeCategory,
    onChangeStore,
    onChangeQuery,
    onChangeStatuses,
    isItemPage: false,
  });

  return (
    <IonPageWrapper>
      <Header title="SCREEN.SERIAL.TITLE" />
      <IonContent>
        <PageWrapper maxWidth="1000px">
          <Formik
            innerRef={formRef}
            enableReinitialize={true}
            initialValues={{ statuses: initialStatuses }}
            onSubmit={(values) => {}}>
            {(formik) => (
              <FilterSection>
                <FormSection
                  className="serial-filter"
                  lastColumn={
                    <Button color="secondary" className="filter-submit" size="small" onClick={onSubmit}>
                      {intl('BUTTON.SUBMIT')}
                    </Button>
                  }
                  FORM={filterForm}
                  formik={formik}
                />
              </FilterSection>
            )}
          </Formik>
        </PageWrapper>
        <SerialsSection
          currentState={currentState}
          itemId={itemId}
          ref={childRef}
          page="SERIAL"
          factory={factory}
          onClickItem={onClickItem}
          onClickStore={onClickStore}
          onClickCategory={onClickCategory}
        />
        <SerialScanModal
          items={items}
          item={item}
          currentState={currentState}
          show={showSerialScan}
          onClose={() => setShowSerialScan(false)}
          onRefresh={onRefresh}
        />
        {item && <FabButton onClick={() => childRef.current?.onAddSerial(item)} />}
      </IonContent>
      {features.scanInventory && <FabButton onClick={() => setShowSerialScan(true)} icon={<QrCode />} />}
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  items: state.item.list,
  stores: state.store.stores,
  categories: state.category.flat,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: categoryActions.fetchCategories,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(SerialsScreen));
