import React from 'react';
import { ModalWrapper } from './styles';
import { itemText } from '$gbusiness/helpers/util';
import WarehouseModel from '$fbusiness/models/warehouse';
import WarehouseDetails from './warehouseDetails';

interface WarehouseEditModalProps {
  warehouse: WarehouseModel;
  onClose: Function;
  refreshList: Function;
}

const WarehouseEditModal: React.FC<WarehouseEditModalProps> = ({ warehouse, onClose, refreshList }) => {
  const titleText = itemText(warehouse?.id ? 'EDIT' : 'CREATE', 'WAREHOUSE');

  return (
    <ModalWrapper show={!!warehouse} onClose={() => onClose()} titleText={titleText} useCustom>
      {warehouse && <WarehouseDetails warehouse={warehouse} refreshList={refreshList} onClose={onClose} />}
    </ModalWrapper>
  );
};

export default WarehouseEditModal;
