import React from 'react';
import { Flex } from '$gstyles/wrapper';
import { dialog } from '$gcomponents/reusables';
import intl from '$gintl';

interface ShippingItemDistributionProps {
  distributions: Array<any>;
  onChangeQty: Function;
}

const ShippingItemDistribution: React.FC<ShippingItemDistributionProps> = ({
  distributions,
  onChangeQty,
}) => {
  const onChangeInput = (e, index, blur) => {
    const value = e.target.value;
    const maxQty = distributions[index].maxQty;
    if (Number(value) > maxQty) {
      e.preventDefault();
      dialog.alert({ message: intl('MESSAGE.DISTRIBUTION_MAX', { max: maxQty }) });
      return;
    }
    const totalQty = distributions.reduce(
      (acc, dist, i) => acc + (i === index ? Number(value) : Number(dist.quantity)),
      0,
    );
    onChangeQty(totalQty, index, value, blur);
  };
  return (
    <div>
      {distributions.map((dist, j) => (
        <Flex className="shipping-dist">
          <div className="name">{dist.short}</div>
          <input
            type="number"
            onFocus={(e) => e.target.select()}
            className="qty"
            value={dist.quantity}
            onBlur={(e) => onChangeInput(e, j, true)}
            onChange={(e) => onChangeInput(e, j, false)}
          />
        </Flex>
      ))}
    </div>
  );
};

export default ShippingItemDistribution;
