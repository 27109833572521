import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonFooter, useIonViewWillLeave } from '@ionic/react';

import intl from '$intl';
import { screen } from '$fcomponents/hoc';
import { Div, SPACE } from '$gstyles';
import { Form, Formik } from 'formik';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import { input } from '$gbusiness/helpers';
import { Flex } from '$gstyles/wrapper';
import Header from '$components/header';
import { storeActions } from '$fbusiness/redux/store';
import StoreModel from '$fbusiness/models/store';

import {
  STORE_FORM,
  /* STORE_USERS_FORM, */ STORE_DISCOUNT_FORM,
  STORE_REGION_FORM,
  STORE_TERM_FORM,
} from './storeForm';
import { IonPageWrapper } from './styles';
import FactoryModel from '$fbusiness/models/factory';
import { userActions } from '$fbusiness/redux/user';
import UserModel from '$gbusiness/models/user';
import PATH from '$business/enums/paths';
import CreditsList from '$fcomponents/creditList';
import CurrentStateModel from '$fbusiness/models/currentState';
import { getAccess, isAccessible } from '$fbusiness/helpers/util';
import { checkEmailAvailability } from '$fbusiness/redux/factory/actions';

interface StoreDetailsScreenProps {
  history: any;
  factory: FactoryModel;
  store: StoreModel | null;
  users: Array<UserModel>;
  saveStore: Function;
  fetchStores: Function;
  toggleActivation: Function;
  currentState: CurrentStateModel;
  isFinished: boolean;
}

const StoreDetailsScreen: React.FC<StoreDetailsScreenProps> = ({
  history,
  factory,
  store,
  users,
  toggleActivation,
  fetchStores,
  currentState,
  saveStore,
  isFinished,
}) => {
  const [emailAvaialble, setEmailAvailable] = React.useState(true);
  const formRef = useRef<any>();
  useEffect(() => {
    if (isFinished) history.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  useIonViewWillLeave(() => {});

  if (store === null)
    return (
      <IonPageWrapper>
        <Header title=""></Header>
      </IonPageWrapper>
    );
  const ACCESS = getAccess(currentState);
  const { id, address, shippingAddress, discounts, termId, active, createdAt, ...inititalValues } = store;
  const isEdit = !!id;
  const titleKey = isEdit ? 'EDIT' : 'CREATE_NEW';
  const canSave = isAccessible(id ? ACCESS.ACTION.STORE.EDIT : ACCESS.ACTION.STORE.CREATE, currentState);

  const onSubmit = async (values) => {
    const { formatted_place, termId, ...newValues } = values;
    await saveStore(id, {
      ...newValues,
      address: values.address,
      factoryId: undefined,
      termId: termId === '0' || !termId ? null : termId,
      ...(values.password && { password: values.password }),
      settings: {
        ...newValues.settings,
      },
    });
    fetchStores();
  };
  const closeScreen = () => {
    history.push(PATH.STORES);
  };

  const onReactivate = async () => {
    await toggleActivation(id, { active: 1 });
    fetchStores();
  };

  const onBlurEmail = async ({ value }) => {
    const result = await checkEmailAvailability(value, isEdit ? [store.username] : []);
    setEmailAvailable(result);
    formRef.current.setFieldError('username', result ? undefined : intl('EMAIL_EXISTS'));
  };

  const storeForm = STORE_FORM(onBlurEmail, emailAvaialble, isEdit);

  const validateForm = (values) => {
    return input.validateError(storeForm, values);
  };

  // const isSalesmen = factory?.settings?.salesmen || false;
  const discountEnabled = factory?.settings?.discounts || false;
  const regionEnabled = factory?.settings?.regions || false;
  const termEnabled = factory?.settings?.terms || false;
  const inventoryEnabled = factory?.settings?.inventory || false;
  const storeLevelEnabled = factory?.settings?.storeLevel || false;

  return (
    <IonPageWrapper>
      <Header titleText={intl('ITEM.' + titleKey, { item: intl('COMMON.STORE') })} />
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          ...inititalValues,
          termId: termId || null,
          address: address?.formatted || '',
          shippingAddress: shippingAddress?.formatted || '',
          ...(id > 0 && {
            ...(address && { address_place: address }),
            ...(shippingAddress && { shippingAddress_place: shippingAddress }),
          }),
          ...(discountEnabled &&
            !inititalValues.discountId && {
              discountId: '0',
            }),
        }}
        validate={validateForm}
        onSubmit={(values) => {
          const { discountId, ...rest } = values;
          onSubmit({
            ...rest,
            discountId: discountId === '0' || !discountId ? null : discountId,
          });
        }}>
        {(formik) => (
          <>
            <IonContent className={!active ? 'disabled o50' : ''}>
              <Div padding={SPACE.LARGE} maxWidth="1000px">
                <Form>
                  <FormSection FORM={storeForm} formik={formik} />
                  {regionEnabled && factory.regions.length > 0 && (
                    <FormSection FORM={STORE_REGION_FORM(factory.regions)} formik={formik} />
                  )}
                  {termEnabled && (
                    <FormSection
                      FORM={STORE_TERM_FORM(
                        factory?.terms,
                        inventoryEnabled ? factory?.warehouses || [] : [],
                        storeLevelEnabled ? factory?.storeLevels || [] : [],
                      )}
                      formik={formik}
                    />
                  )}
                  {discountEnabled && factory.discounts && factory.discounts.length > 0 && (
                    <FormSection
                      FORM={STORE_DISCOUNT_FORM(
                        factory.discounts,
                        formRef.current?.values?.settings?.discounts || [],
                      )}
                      formik={formik}
                    />
                  )}
                  {/* {isSalesmen && users.length > 0 && (
                    <FormSection FORM={STORE_USERS_FORM(users)} formik={formik} />
                  )} */}
                </Form>
                {id > 0 &&
                  isAccessible(ACCESS.ACTION.CREDIT.VIEW, currentState) &&
                  isAccessible(ACCESS.MONEY.VIEW, currentState) && (
                    <CreditsList store={store} currentState={currentState} editable />
                  )}
              </Div>
            </IonContent>
            <IonFooter>
              <Flex padding={SPACE.MEDIUM} justifyContent="space-between">
                <Button variant="outlined" onClick={closeScreen}>
                  {intl('BUTTON.CANCEL')}
                </Button>
                {!store.active ? (
                  <Button
                    color="primary"
                    className="submit-button"
                    variant="contained"
                    onClick={onReactivate}>
                    {intl('BUTTON.REACTIVATE')}
                  </Button>
                ) : (
                  <Button
                    disabled={!formik.isValid || !canSave}
                    color="primary"
                    className="submit-button"
                    variant="contained"
                    onClick={formik.handleSubmit}>
                    {intl('BUTTON.SUBMIT')}
                  </Button>
                )}
              </Flex>
            </IonFooter>
          </>
        )}
      </Formik>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  store: state.store.store,
  users: state.user.users,
  isFinished: state.store.isFinished,
  resetOnRoute: true,
});

const mapDispatchToProps = (dispatch) => ({
  onHydrate: (params) => {
    dispatch(storeActions.fetchStore(parseInt(params.storeId)));
    dispatch(userActions.fetchUsers({ storeUser: true }));
  },
  onDehydrate: () => dispatch(storeActions.dehydrate()),
  saveStore: (n, o) => dispatch(storeActions.saveStore(n, o)),
  toggleActivation: (n, v) => dispatch(storeActions.saveStore(n, v)),
  fetchStores: () => dispatch(storeActions.fetchStores()),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(StoreDetailsScreen));
