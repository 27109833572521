import { Modal } from '$gcomponents/reusables';
import { FONT } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';
import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const IonPageWrapper = styled(IonPage)`
  .serial-filter {
    .last-column {
      align-items: center;
    }
    .filter-submit {
      padding: 10px 4px;
      margin-top: 8px;
    }
  }
  .note {
    font-size: ${FONT.XSMALL};
  }
`;

export const BatchSectionContainer = styled.div`
  .search-container {
    display: flex;
    justify-content: flex-start;
  }
  .fixed {
    position: fixed;
  }
`;

export const ScanModalWrapper = styled(Modal)`
  .scan-input {
    display: block;
    width: 100%;
    padding: 20px;
    text-align: center;
    border: 1px solid #999;
    border-radius: 5px;
    margin-bottom: 30px;
    font-size: ${FONT.LARGE};
  }
  .item-input-wrapper {
    height: 90px;
  }
`;

export const SerialSelectModalWrapper = styled(Modal)`
  .qty-header {
    padding: 4px 16px 8px 16px;
  }
  .full-height {
    height: 100%;
  }
  .left-container,
  .right-container {
    display: flex;
    height: 100%;
    flex: 9;
    flex-direction: column;
  }

  .space-between {
    flex: 1;
    justify-content: space-between;
  }

  .expire {
    font-size: 0.9em;
  }

  .right-container {
    margin-left: 20px;
    flex: 7;
    .title {
      text-transform: uppercase;
      font-size: 1.1em;
      margin: 8px 0;
      font-weight: 600;
      text-align: center;
    }
    .item {
      justify-content: space-between;
      padding: 2px 8px;
      box-shadow: 1px 1px 1px #ccc;
      background-color: #fafafa;
      margin: 1px;
      .expire {
        margin-right: 8px;
      }
    }
  }

  .list-container {
    overflow-y: auto;
    flex: 1;
    padding: 4px;
    border: 1px solid var(--ion-border-color);
    height: calc(100% - 40px);
    > div {
      display: flex;
      align-items: center;
      padding: 4px;
      .MuiCheckbox-root {
        padding: 0;
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  ${mobileStyle(`
    .expire {
      display: none;
    }
  `)}
`;
