import _ from 'lodash';
import { INPUT } from '$gbusiness/enums';

const generateAccessForm = (value, path, roles, prefix, accessType) => {
  return Object.entries(value || {}).reduce((acc: any, [key, value2]) => {
    if (typeof value2 === 'number' || typeof value2 === 'string') {
      var fullPath = `${path ? path + '.' : ''}${key}`;
      acc.push({
        label: `ACCESS.${accessType}.${fullPath}`,
        key: fullPath.replace(/\./g, '_'),
        value: `${prefix ? prefix + '.' : ''}${fullPath}`,
        input: INPUT.RADIO,
        defaultValue: value2,
        options: roles,
        gridSize: 12,
      });
      return acc;
    }
    const path2 = path ? `${path}.${key}` : key;
    acc = acc.concat(generateAccessForm(value2, path2, roles, prefix, accessType));
    return acc;
  }, []);
};

export const convertFormToValue = (form) => {
  let obj: any = {};
  for (const formInput of form[0].items) {
    obj = _.set(obj, formInput.value, formInput.defaultValue.toString());
  }
  return obj?.settings?.ACCESS;
};

export const traverseObject = (obj, callback) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      callback(key, obj[key]);

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        traverseObject(obj[key], callback);
      }
    }
  }
};

export const convertAccessToNumber = (access) => {
  traverseObject(access, (key, value) => {
    if (typeof value === 'string') {
      access[key] = parseInt(value);
    }
  });
  return access;
};

export const ACCESS_FORM = (value, roles, prefix, accessType = 'ACTION') => [
  {
    items: generateAccessForm(value, '', roles, prefix, accessType),
  },
];

export default ACCESS_FORM;
